import React, { useState, useCallback } from 'react';
import { FaArrowLeft, FaBrain, FaMicrochip, FaChartLine } from 'react-icons/fa';
import * as styles from './ModelHub.module.css';

const ModelHub = ({ onClose, onInferenceToggle, isInferenceRunning }) => {
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  const handleModelSelect = (modelName) => {
    onInferenceToggle(modelName);
  };

  return (
    <div className={`${styles.modelHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>
      
      <div className={styles.modelList}>
        <div className={`${styles.modelCard} ${isInferenceRunning ? styles.active : ''}`}>
          <img src="assets/images/mobilenet.png" alt="MobileNetV2" className={styles.modelImage} />
          
          <div className={styles.modelInfo}>
            <h3>MobileNetV2</h3>
            <p className={styles.modelDescription}>Efficient real-time classification model</p>
            
            <div className={styles.modelStatus}>
              <FaBrain style={{ color: isInferenceRunning ? '#00F135' : '#666' }} />
              <span>{isInferenceRunning ? 'Running' : 'Ready'}</span>
            </div>
            
            <div className={styles.modelStats}>
              <div className={styles.stat}>
                <FaMicrochip />
                <span>Light-weight</span>
              </div>
              <div className={styles.stat}>
                <FaChartLine />
                <span>Fast Inference</span>
              </div>
            </div>
          </div>

          <div className={styles.modelActions}>
            <button className={styles.learnMore}>
              Learn More
            </button>
            <button 
              className={`${styles.select} ${isInferenceRunning ? styles.active : ''}`}
              onClick={() => handleModelSelect('MobileNetV2')}
            >
              {isInferenceRunning ? 'Stop Inference' : 'Start Inference'}
            </button>
          </div>
        </div>

        <div className={`${styles.modelCard} ${styles.disabled}`}>
          <img src="assets/images/filler.png" alt="Coming Soon" className={styles.modelImage} />
          
          <div className={styles.modelInfo}>
            <h3>More Models Coming Soon</h3>
            <p className={styles.modelDescription}>Additional AI models will be available in future updates</p>
            
            <div className={styles.modelStatus}>
              <FaBrain style={{ color: '#666' }} />
              <span>Not Available</span>
            </div>
          </div>

          <div className={styles.modelActions}>
            <button className={styles.learnMore} disabled>
              Learn More
            </button>
            <button className={styles.select} disabled>
              Not Available
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelHub; 