import * as THREE from 'three';

class StabilizationVisualizer {
    constructor(mountElement) {
        // Create container with aesthetic matching existing UI
        this.container = document.createElement('div');
        this.container.style.position = 'absolute';
        this.container.style.top = '80px';  // Below the mode toggle button
        this.container.style.right = '10px';
        this.container.style.width = '200px';
        this.container.style.height = '200px';
        this.container.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
        this.container.style.border = '1px solid rgba(0, 241, 53, 0.2)';
        this.container.style.borderRadius = '8px';
        this.container.style.boxShadow = '0 0 20px rgba(0, 241, 53, 0.1)';
        this.container.style.zIndex = '1000';

        // Create canvas for 2D visualization
        this.canvas = document.createElement('canvas');
        this.canvas.width = 200;
        this.canvas.height = 200;
        this.canvas.style.width = '100%';
        this.canvas.style.height = '100%';
        this.container.appendChild(this.canvas);

        // Add title
        const title = document.createElement('div');
        title.style.position = 'absolute';
        title.style.top = '-25px';
        title.style.left = '0';
        title.style.width = '100%';
        title.style.textAlign = 'center';
        title.style.color = '#00F135';
        title.style.fontSize = '0.8rem';
        title.style.fontFamily = 'Arial, sans-serif';
        title.innerText = 'Stabilization Debug';
        this.container.appendChild(title);

        mountElement.appendChild(this.container);

        this.ctx = this.canvas.getContext('2d');
        this.trajectoryPoints = [];
        this.maxTrajectoryPoints = 50;  // Number of points to keep in trajectory
        this.fadeOutTime = 2000;  // Time in ms for trajectory points to fade out

        // Initialize center position
        this.centerX = this.canvas.width / 2;
        this.centerY = this.canvas.height / 2;

        // Debug values display
        this.debugValues = document.createElement('div');
        this.debugValues.style.position = 'absolute';
        this.debugValues.style.bottom = '-65px';
        this.debugValues.style.left = '0';
        this.debugValues.style.width = '100%';
        this.debugValues.style.color = '#00F135';
        this.debugValues.style.fontSize = '0.7rem';
        this.debugValues.style.fontFamily = 'monospace';
        this.debugValues.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
        this.debugValues.style.padding = '5px';
        this.debugValues.style.borderRadius = '4px';
        this.debugValues.style.border = '1px solid rgba(0, 241, 53, 0.2)';
        this.container.appendChild(this.debugValues);

        // Store mount element reference
        this.mountElement = mountElement;
        
        // Ensure the container is mounted
        if (!this.mountElement.contains(this.container)) {
            this.mountElement.appendChild(this.container);
        }
    }

    /**
     * Updates the visualization with new drone orientation and PID values
     * @param {Object} orientation - Current drone orientation {pitch, roll, yaw}
     * @param {Object} pidOutput - Current PID controller output
     * @param {Object} setpoint - Current PID setpoint
     */
    update(orientation, pidOutput, setpoint) {
        if (!this.ctx) return;  // Guard against missing context

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Draw coordinate grid
        this.drawGrid();

        // Calculate drone position based on roll and pitch
        // Scale factor determines how much the dot moves based on angle
        const scaleFactor = 1.5;
        const x = this.centerX + (orientation.roll * scaleFactor);
        const y = this.centerY + (orientation.pitch * scaleFactor);

        // Add new point to trajectory
        this.trajectoryPoints.push({
            x,
            y,
            timestamp: Date.now()
        });

        // Remove old points
        const now = Date.now();
        this.trajectoryPoints = this.trajectoryPoints.filter(point => 
            now - point.timestamp < this.fadeOutTime
        );

        // Draw trajectory
        this.drawTrajectory();

        // Draw current position
        this.drawDronePosition(x, y, orientation.yaw);

        // Draw target position
        this.drawSetpoint(setpoint);

        // Update debug values
        this.updateDebugValues(
            orientation || { roll: 0, pitch: 0, yaw: 0 },
            pidOutput || { roll: 1500, pitch: 1500, yaw: 1500 },
            setpoint || { roll: 0, pitch: 0, yaw: 0 }
        );
    }

    drawGrid() {
        this.ctx.strokeStyle = 'rgba(0, 241, 53, 0.1)';
        this.ctx.lineWidth = 1;

        // Draw circles
        for (let r = 20; r <= 80; r += 20) {
            this.ctx.beginPath();
            this.ctx.arc(this.centerX, this.centerY, r, 0, Math.PI * 2);
            this.ctx.stroke();
        }

        // Draw crosshairs
        this.ctx.beginPath();
        this.ctx.moveTo(this.centerX, 0);
        this.ctx.lineTo(this.centerX, this.canvas.height);
        this.ctx.moveTo(0, this.centerY);
        this.ctx.lineTo(this.canvas.width, this.centerY);
        this.ctx.stroke();
    }

    drawTrajectory() {
        const now = Date.now();
        
        for (let i = 1; i < this.trajectoryPoints.length; i++) {
            const point = this.trajectoryPoints[i];
            const prevPoint = this.trajectoryPoints[i - 1];
            
            // Calculate opacity based on age
            const age = now - point.timestamp;
            const opacity = 1 - (age / this.fadeOutTime);
            
            this.ctx.beginPath();
            this.ctx.strokeStyle = `rgba(0, 241, 53, ${opacity})`;
            this.ctx.lineWidth = 2;
            this.ctx.moveTo(prevPoint.x, prevPoint.y);
            this.ctx.lineTo(point.x, point.y);
            this.ctx.stroke();
        }
    }

    drawDronePosition(x, y, yaw) {
        // Draw drone position indicator
        this.ctx.beginPath();
        this.ctx.arc(x, y, 5, 0, Math.PI * 2);
        this.ctx.fillStyle = '#00F135';
        this.ctx.fill();

        // Draw yaw indicator line
        const yawLength = 15;
        const yawX = x + Math.sin(yaw * Math.PI / 180) * yawLength;
        const yawY = y - Math.cos(yaw * Math.PI / 180) * yawLength;
        
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(yawX, yawY);
        this.ctx.strokeStyle = '#00F135';
        this.ctx.lineWidth = 2;
        this.ctx.stroke();
    }

    drawSetpoint(setpoint) {
        // Draw target position
        this.ctx.beginPath();
        this.ctx.arc(this.centerX, this.centerY, 5, 0, Math.PI * 2);
        this.ctx.strokeStyle = 'rgba(0, 241, 53, 0.5)';
        this.ctx.lineWidth = 2;
        this.ctx.stroke();
    }

    updateDebugValues(orientation, pidOutput, setpoint) {
        this.debugValues.innerHTML = `
            <div style="display: flex; justify-content: space-between;">
                <span>Roll: ${orientation.roll.toFixed(1)}°</span>
                <span>Pitch: ${orientation.pitch.toFixed(1)}°</span>
                <span>Yaw: ${orientation.yaw.toFixed(1)}°</span>
            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 4px;">
                <span>PID-R: ${((pidOutput.roll - 1500) / 1500).toFixed(2)}</span>
                <span>PID-P: ${((pidOutput.pitch - 1500) / 1500).toFixed(2)}</span>
                <span>PID-Y: ${((pidOutput.yaw - 1500) / 1500).toFixed(2)}</span>
            </div>
        `;
    }

    /**
     * Shows or hides the visualizer
     * @param {boolean} visible - Whether to show the visualizer
     */
    setVisible(visible) {
        // Ensure container exists and is mounted before changing visibility
        if (!this.container) return;
        
        if (visible && !this.mountElement.contains(this.container)) {
            this.mountElement.appendChild(this.container);
        }
        
        this.container.style.display = visible ? 'block' : 'none';
    }
}

export default StabilizationVisualizer; 