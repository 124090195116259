import React, { useEffect, useState } from 'react';
import { useSimulation } from '../context/simulationContext';
import * as styles from './SimulationHub.module.css';
import GamepadHandler from '../utils/gamepadHandler';
import { FaBrain, FaRedo, FaGlobe, FaRobot, FaChartLine, FaCode, FaGraduationCap } from 'react-icons/fa';
import ModelHub from './ModelHub';
import EnvironmentHub from './EnvironmentHub';
import PerformanceHub from './PerformanceHub';
import ProgramHub from './ProgramHub';
import RLHub from './RLHub';

/**
 * @component SimulationHub
 * @description A sleek sidebar component providing navigation and status for the drone simulation.
 */
const SimulationHub = () => {
  const [gamepadConnected, setGamepadConnected] = useState(false);
  const [showModelHub, setShowModelHub] = useState(false);
  const [showEnvironmentHub, setShowEnvironmentHub] = useState(false);
  const [showPerformanceHub, setShowPerformanceHub] = useState(false);
  const [showProgramHub, setShowProgramHub] = useState(false);
  const [showRLHub, setShowRLHub] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const { isInferenceRunning, toggleInference, resetDrone } = useSimulation();

  const handleInferenceToggle = (modelName) => {
    toggleInference(modelName);
  };

  const handleTrainingToggle = (config) => {
    setIsTraining(!isTraining);
    // TODO: Implement actual training logic with config
    console.log('Training config:', config);
  };

  useEffect(() => {
    const gamepadHandler = new GamepadHandler();
    
    const checkGamepadConnection = () => {
      setGamepadConnected(gamepadHandler.connected);
    };

    checkGamepadConnection();
    const intervalId = setInterval(checkGamepadConnection, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleReset = () => {
    resetDrone();
  };

  return (
    <div className={`${styles.hub} ${
      showModelHub || showEnvironmentHub || showPerformanceHub || showProgramHub || showRLHub
        ? showProgramHub 
          ? styles.programExpanded 
          : styles.expanded 
        : ''
    }`}>
      <div className={styles.glowBackground}></div>
      <div className={styles.content}>
        {!showModelHub && !showEnvironmentHub && !showPerformanceHub && !showProgramHub && !showRLHub ? (
          <>
            <h2 className={styles.title}>DF</h2>
            <nav className={styles.nav}>
              <button 
                className={styles.navButton} 
                title="Program Drone"
                onClick={() => setShowProgramHub(true)}
              >
                <FaCode />
                <span className={styles.buttonText}>Program</span>
              </button>
              <button 
                className={styles.navButton} 
                title="Load AI Models"
                onClick={() => setShowModelHub(true)}
              >
                <FaBrain />
                <span className={styles.buttonText}>Models</span>
              </button>
              <button 
                className={styles.navButton} 
                title="Train RL Models"
                onClick={() => setShowRLHub(true)}
              >
                <FaGraduationCap />
                <span className={styles.buttonText}>Train AI</span>
              </button>
              <button 
                className={styles.navButton} 
                title="Modify Environment"
                onClick={() => setShowEnvironmentHub(true)}
              >
                <FaGlobe />
                <span className={styles.buttonText}>Environment</span>
              </button>
              <button 
                className={styles.navButton} 
                title="Performance Metrics"
                onClick={() => {
                  console.log('Performance button clicked');
                  setShowPerformanceHub(true);
                }}
              >
                <FaChartLine />
                <span className={styles.buttonText}>Performance</span>
              </button>
              <button 
                className={styles.navButton} 
                title="Drone Settings"
              >
                <FaRobot />
                <span className={styles.buttonText}>Settings</span>
              </button>
            </nav>
            <div className={styles.gamepadStatus}>
              <div className={`${styles.statusIndicator} ${gamepadConnected ? styles.connected : styles.disconnected}`}>
                {gamepadConnected ? 'Controller Connected' : 'Controller Disconnected'}
              </div>
            </div>
          </>
        ) : showProgramHub ? (
          <ProgramHub onClose={() => setShowProgramHub(false)} />
        ) : showModelHub ? (
          <ModelHub 
            onClose={() => setShowModelHub(false)} 
            onInferenceToggle={handleInferenceToggle}
            isInferenceRunning={isInferenceRunning}
          />
        ) : showEnvironmentHub ? (
          <EnvironmentHub onClose={() => setShowEnvironmentHub(false)} />
        ) : showRLHub ? (
          <RLHub 
            onClose={() => setShowRLHub(false)}
            onTrainingToggle={handleTrainingToggle}
            isTraining={isTraining}
          />
        ) : (
          <PerformanceHub onClose={() => setShowPerformanceHub(false)} />
        )}
      </div>
    </div>
  );
};

export default SimulationHub;
