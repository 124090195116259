import React, { useState, useRef, useEffect } from 'react';
import { FaArrowLeft, FaPlay, FaStop, FaPause, FaGripLines, FaTimes } from 'react-icons/fa';
import * as styles from './ProgramHub.module.css';
import { useSimulation } from '../context/simulationContext';
import ProgramExecutor from '../utils/programExecutor';

const ProgramHub = ({ onClose }) => {
  const { simulationRef } = useSimulation();
  const [isExiting, setIsExiting] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [draggedBlock, setDraggedBlock] = useState(null);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const blockRefs = useRef({});
  const executorRef = useRef(null);
  const [units, setUnits] = useState({
    height: 'm',
    duration: 's'
  });

  useEffect(() => {
    const initializeExecutor = () => {
      if (!simulationRef?.current?.physicsEngine || !simulationRef?.current?.controls) {
        console.log('Waiting for simulation initialization...');
        return false;
      }

      try {
        executorRef.current = new ProgramExecutor(simulationRef);
        console.log('Program executor initialized successfully');
        return true;
      } catch (error) {
        console.error('Failed to initialize program executor:', error);
        return false;
      }
    };

    // Try to initialize immediately
    if (!initializeExecutor()) {
      // If failed, set up an interval to retry
      const intervalId = setInterval(() => {
        if (initializeExecutor()) {
          clearInterval(intervalId);
        }
      }, 500); // Try every 500ms

      // Clean up interval if component unmounts
      return () => clearInterval(intervalId);
    }
  }, [simulationRef]);

  const runProgram = async () => {
    if (!executorRef.current) {
      console.error('Program executor not initialized');
      return;
    }

    setIsRunning(true);
    
    try {
      executorRef.current.setBlocks(blocks);
      await executorRef.current.start();
    } catch (error) {
      console.error('Error running program:', error);
    } finally {
      setIsRunning(false);
    }
  };

  const stopProgram = () => {
    if (executorRef.current) {
      executorRef.current.stop();
      setIsRunning(false);
    }
  };

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleDragStart = (e, block, index) => {
    // Prevent default drag ghost image
    const dragGhost = document.createElement('div');
    dragGhost.style.position = 'absolute';
    dragGhost.style.top = '-9999px';
    document.body.appendChild(dragGhost);
    e.dataTransfer.setDragImage(dragGhost, 0, 0);
    
    // Remove after drag starts
    setTimeout(() => document.body.removeChild(dragGhost), 0);
    
    setDraggedBlock({ ...block, sourceIndex: index });
    e.currentTarget.classList.add(styles.dragging);
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove(styles.dragging);
    setDraggedBlock(null);
    setDraggedOverIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedBlock && draggedBlock.sourceIndex !== index) {
      setDraggedOverIndex(index);
    }
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    if (!draggedBlock) return;

    const newBlocks = [...blocks];
    const sourceIndex = draggedBlock.sourceIndex;
    
    // Remove block from old position
    newBlocks.splice(sourceIndex, 1);
    // Insert at new position
    newBlocks.splice(targetIndex, 0, draggedBlock);
    
    setBlocks(newBlocks);
    setDraggedBlock(null);
    setDraggedOverIndex(null);
  };

  const handleAddBlock = (blockType) => {
    const newBlock = {
      id: Date.now(),
      type: blockType,
      params: getDefaultParamsForBlock(blockType)
    };
    setBlocks([...blocks, newBlock]);
  };

  const getDefaultParamsForBlock = (blockType) => {
    switch (blockType) {
      case 'takeoff':
        return { height: 1.0 };
      case 'land':
        return {};
      case 'hover':
        return { duration: 3.0, height: 1.0 };
      case 'moveForward':
      case 'moveBackward':
      case 'strafeLeft':
      case 'strafeRight':
        return { duration: 2.0 };
      case 'rotateLeft':
      case 'rotateRight':
        return { angle: 90, duration: 2.0 };
      default:
        return {};
    }
  };

  const handleDeleteBlock = (index) => {
    const newBlocks = [...blocks];
    newBlocks.splice(index, 1);
    setBlocks(newBlocks);
  };

  const convertValue = (value, from, to) => {
    if (from === to) return value;
    
    // Height conversions
    if (from === 'm' && to === 'ft') return value * 3.28084;
    if (from === 'ft' && to === 'm') return value / 3.28084;
    
    // Duration conversions
    if (from === 's' && to === 'ms') return value * 1000;
    if (from === 'ms' && to === 's') return value / 1000;
    
    return value;
  };

  const toggleUnit = (paramType) => {
    setUnits(prev => {
      const newUnits = { ...prev };
      if (paramType === 'height') {
        newUnits.height = prev.height === 'm' ? 'ft' : 'm';
      } else if (paramType === 'duration') {
        newUnits.duration = prev.duration === 's' ? 'ms' : 's';
      }
      return newUnits;
    });
  };

  return (
    <div className={`${styles.programHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>

      <div className={styles.content}>
        <div className={styles.blockPalette}>
          <h3>Available Blocks</h3>
          <div className={styles.blockCategories}>
            <div className={styles.category}>
              <h4>Basic</h4>
              <div className={styles.blockList}>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('takeoff')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'takeoff', params: getDefaultParamsForBlock('takeoff') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Takeoff
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('land')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'land', params: getDefaultParamsForBlock('land') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Land
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('hover')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'hover', params: getDefaultParamsForBlock('hover') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Hover
                </button>
              </div>
            </div>

            <div className={styles.category}>
              <h4>Movement</h4>
              <div className={styles.blockList}>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('moveForward')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'moveForward', params: getDefaultParamsForBlock('moveForward') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Move Forward
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('moveBackward')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'moveBackward', params: getDefaultParamsForBlock('moveBackward') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Move Backward
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('strafeLeft')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'strafeLeft', params: getDefaultParamsForBlock('strafeLeft') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Strafe Left
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('strafeRight')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'strafeRight', params: getDefaultParamsForBlock('strafeRight') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Strafe Right
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('rotateLeft')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'rotateLeft', params: getDefaultParamsForBlock('rotateLeft') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Rotate Left
                </button>
                <button 
                  className={styles.blockButton} 
                  onClick={() => handleAddBlock('rotateRight')}
                  draggable
                  onDragStart={(e) => handleDragStart(e, { type: 'rotateRight', params: getDefaultParamsForBlock('rotateRight') })}
                >
                  <FaGripLines className={styles.dragHandle} />
                  Rotate Right
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.workspace}>
          <div className={styles.programControls}>
            <button 
              className={`${styles.controlButton} ${isRunning ? styles.active : ''}`}
              onClick={isRunning ? stopProgram : runProgram}
            >
              {isRunning ? <FaStop /> : <FaPlay />}
            </button>
            <button 
              className={`${styles.controlButton} ${isPaused ? styles.active : ''}`}
              onClick={() => setIsPaused(!isPaused)}
              disabled={!isRunning}
            >
              <FaPause />
            </button>
          </div>

          <div className={styles.blockWorkspace}>
            {blocks.map((block, index) => (
              <div
                key={block.id}
                ref={el => blockRefs.current[block.id] = el}
                className={`${styles.programBlock} ${draggedOverIndex === index ? styles.dropTarget : ''}`}
                draggable
                onDragStart={(e) => handleDragStart(e, block, index)}
                onDragEnd={handleDragEnd}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) => handleDrop(e, index)}
              >
                <div className={styles.blockHandle}>
                  <FaGripLines className={styles.dragHandle} />
                </div>
                <div className={styles.blockContent}>
                  <span className={styles.blockType}>{block.type}</span>
                  {block.type === 'takeoff' && (
                    <div className={styles.parameterContainer}>
                      <span className={styles.parameterLabel}>Height:</span>
                      <input 
                        type="number" 
                        value={units.height === 'm' ? block.params.height : convertValue(block.params.height, 'm', 'ft')}
                        onChange={(e) => {
                          const value = units.height === 'm' ? 
                            parseFloat(e.target.value) : 
                            convertValue(parseFloat(e.target.value), 'ft', 'm');
                          const updatedBlocks = blocks.map(b => 
                            b.id === block.id 
                              ? { ...b, params: { height: value } }
                              : b
                          );
                          setBlocks(updatedBlocks);
                        }}
                        step="0.1"
                        min="0"
                        max={units.height === 'm' ? 10 : 32.8}
                        className={styles.parameterInput}
                      />
                      <span 
                        className={styles.parameterUnit}
                        onClick={() => toggleUnit('height')}
                        title="Click to toggle units"
                      >
                        {units.height}
                      </span>
                    </div>
                  )}
                  {block.type === 'hover' && (
                    <>
                      <div className={styles.parameterContainer}>
                        <span className={styles.parameterLabel}>Duration:</span>
                        <input 
                          type="number" 
                          value={units.duration === 's' ? block.params.duration : convertValue(block.params.duration, 's', 'ms')}
                          onChange={(e) => {
                            const value = units.duration === 's' ? 
                              parseFloat(e.target.value) : 
                              convertValue(parseFloat(e.target.value), 'ms', 's');
                            const updatedBlocks = blocks.map(b => 
                              b.id === block.id 
                                ? { ...b, params: { ...b.params, duration: value } }
                                : b
                            );
                            setBlocks(updatedBlocks);
                          }}
                          step={units.duration === 's' ? 0.1 : 100}
                          min="0"
                          max={units.duration === 's' ? 30 : 30000}
                          className={styles.parameterInput}
                        />
                        <span 
                          className={styles.parameterUnit}
                          onClick={() => toggleUnit('duration')}
                          title="Click to toggle units"
                        >
                          {units.duration}
                        </span>
                      </div>
                      <div className={styles.parameterContainer}>
                        <span className={styles.parameterLabel}>Height:</span>
                        <input 
                          type="number" 
                          value={units.height === 'm' ? block.params.height || 1.0 : convertValue(block.params.height || 1.0, 'm', 'ft')}
                          onChange={(e) => {
                            const value = units.height === 'm' ? 
                              parseFloat(e.target.value) : 
                              convertValue(parseFloat(e.target.value), 'ft', 'm');
                            const updatedBlocks = blocks.map(b => 
                              b.id === block.id 
                                ? { ...b, params: { ...b.params, height: value } }
                                : b
                            );
                            setBlocks(updatedBlocks);
                          }}
                          step="0.1"
                          min="0"
                          max={units.height === 'm' ? 10 : 32.8}
                          className={styles.parameterInput}
                        />
                        <span 
                          className={styles.parameterUnit}
                          onClick={() => toggleUnit('height')}
                          title="Click to toggle units"
                        >
                          {units.height}
                        </span>
                      </div>
                    </>
                  )}
                  {(block.type === 'moveForward' || 
                    block.type === 'moveBackward' || 
                    block.type === 'strafeLeft' || 
                    block.type === 'strafeRight') && (
                    <div className={styles.parameterContainer}>
                      <span className={styles.parameterLabel}>Duration:</span>
                      <input 
                        type="number" 
                        value={units.duration === 's' ? block.params.duration : convertValue(block.params.duration, 's', 'ms')}
                        onChange={(e) => {
                          const value = units.duration === 's' ? 
                            parseFloat(e.target.value) : 
                            convertValue(parseFloat(e.target.value), 'ms', 's');
                          const updatedBlocks = blocks.map(b => 
                            b.id === block.id 
                              ? { ...b, params: { duration: value } }
                              : b
                          );
                          setBlocks(updatedBlocks);
                        }}
                        step={units.duration === 's' ? 0.1 : 100}
                        min="0"
                        max={units.duration === 's' ? 30 : 30000}
                        className={styles.parameterInput}
                      />
                      <span 
                        className={styles.parameterUnit}
                        onClick={() => toggleUnit('duration')}
                        title="Click to toggle units"
                      >
                        {units.duration}
                      </span>
                    </div>
                  )}
                  {block.type === 'rotateLeft' && (
                    <div className={styles.parameterContainer}>
                      <span className={styles.parameterLabel}>Angle:</span>
                      <input 
                        type="number" 
                        value={block.params.angle}
                        onChange={(e) => {
                          const updatedBlocks = blocks.map(b => 
                            b.id === block.id 
                              ? { ...b, params: { angle: parseFloat(e.target.value) } }
                              : b
                          );
                          setBlocks(updatedBlocks);
                        }}
                        step="1"
                        min="0"
                        max="360"
                        className={styles.parameterInput}
                      />
                    </div>
                  )}
                  {block.type === 'rotateRight' && (
                    <div className={styles.parameterContainer}>
                      <span className={styles.parameterLabel}>Angle:</span>
                      <input 
                        type="number" 
                        value={block.params.angle}
                        onChange={(e) => {
                          const updatedBlocks = blocks.map(b => 
                            b.id === block.id 
                              ? { ...b, params: { angle: parseFloat(e.target.value) } }
                              : b
                          );
                          setBlocks(updatedBlocks);
                        }}
                        step="1"
                        min="0"
                        max="360"
                        className={styles.parameterInput}
                      />
                    </div>
                  )}
                </div>
                <button 
                  className={styles.deleteButton}
                  onClick={() => handleDeleteBlock(index)}
                  title="Delete block"
                >
                  <FaTimes />
                </button>
                <div className={styles.blockConnector} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramHub; 