export const modelConfigs = {
  'MobileNetV2': {
    path: '/assets/models/mobilenetv2-12.onnx',
    inputSize: [224, 224],
    preprocess: {
      normalize: {
        mean: [0.485, 0.456, 0.406],
        std: [0.229, 0.224, 0.225]
      },
      letterbox: true
    },
    postprocess: {
      type: 'classification',
      topK: 5
    }
  }
}; 