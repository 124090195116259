import * as THREE from 'three';
import { runInferenceOnFrameCapture } from '../inference/modelHelper';
import { pixelDataToTensor } from './imageHelper';
import { modelConfigs } from '../inference/modelConfig';

const DEBUG = true; // Toggle for verbose logging
let boundingBoxOverlay; // Declare at module level

// Add performance monitoring
const perfMetrics = {
  captureTime: 0,
  preprocessTime: 0,
  inferenceTime: 0,
  renderTime: 0,
  totalPipelineTime: 0
};

function logPerformance(stage, time) {
  if (DEBUG) {
    perfMetrics[stage] = time;
    console.log(`🕒 ${stage}: ${time.toFixed(2)}ms`);
    console.log('📊 Current Performance Metrics:', {
      ...perfMetrics,
      totalTime: Object.values(perfMetrics).reduce((a, b) => a + b, 0)
    });
  }
}

// Initialize the inference worker
const inferenceWorker = new Worker(new URL('../workers/inferenceWorker.js', import.meta.url));

// Initialize the worker
inferenceWorker.postMessage({ type: 'init' });

// Listen for messages from the worker
inferenceWorker.onmessage = (e) => {
  const { type, predictions, error, timings } = e.data;
  
  if (type === 'initialized') {
    console.log('🚀 Inference Worker initialized');
  }

  if (type === 'inferenceResult') {
    const renderStart = performance.now();
    if (DEBUG) {
      console.log('✨ Inference results:', predictions);
      if (timings) {
        console.log('⚡ Pipeline timings:', timings);
      }
    }
    
    updateBoundingBoxes(predictions);
    
    const renderTime = performance.now() - renderStart;
    logPerformance('renderTime', renderTime);
    
    // Calculate total pipeline time
    perfMetrics.totalPipelineTime = 
      perfMetrics.captureTime + 
      (timings?.preprocessTime || 0) + 
      (timings?.inferenceTime || 0) + 
      perfMetrics.renderTime;
    
    if (DEBUG) {
      console.log('📊 Total pipeline time:', perfMetrics.totalPipelineTime.toFixed(2) + 'ms');
    }
  }

  if (type === 'error') {
    console.error('❌ Inference Error:', error);
  }
};


/**
 * @function createFPVDisplay
 * @description Creates the First Person View display and returns the FPV renderer.
 * @param {HTMLElement} mountElement - The element to mount the FPV display to.
 * @returns {THREE.WebGLRenderer} The FPV renderer.
 */
export function createFPVDisplay(mountElement) {
    const startTime = performance.now();
    console.log('🎥 Creating FPV display...');
    
    // Create a container for the FPV display
    const fpvContainer = document.createElement('div');
    fpvContainer.style.position = 'absolute';
    fpvContainer.style.top = '2%';
    fpvContainer.style.left = '5%';
    fpvContainer.style.width = '30%';
    fpvContainer.style.maxWidth = '384px';
    fpvContainer.style.aspectRatio = '16 / 9';
    fpvContainer.style.border = '2px solid white';
    fpvContainer.style.zIndex = '10';
    fpvContainer.style.overflow = 'hidden';
    
    mountElement.appendChild(fpvContainer);
    
    if (DEBUG) console.log('📦 FPV container created:', fpvContainer);

    const fpvCanvas = document.createElement('canvas');
    fpvCanvas.style.width = '100%';
    fpvCanvas.style.height = '100%';
    fpvContainer.appendChild(fpvCanvas);

    const fpvRenderer = new THREE.WebGLRenderer({ canvas: fpvCanvas });
    
    // Function to update renderer size
    const updateRendererSize = () => {
        const width = fpvContainer.clientWidth;
        const height = fpvContainer.clientHeight;
        fpvCanvas.width = width;
        fpvCanvas.height = height;
        fpvRenderer.setSize(width, height, false);
        if (DEBUG) console.log('📐 FPV display resized:', { width, height });
    };

    // Initial size update
    updateRendererSize();

    // Add resize listener
    window.addEventListener('resize', updateRendererSize);

    // Create overlay for bounding boxes
    boundingBoxOverlay = document.createElement('div');
    boundingBoxOverlay.style.position = 'absolute';
    boundingBoxOverlay.style.top = '0';
    boundingBoxOverlay.style.left = '0';
    boundingBoxOverlay.style.width = '100%';
    boundingBoxOverlay.style.height = '100%';
    boundingBoxOverlay.style.pointerEvents = 'none';
    fpvContainer.appendChild(boundingBoxOverlay);

    const setupTime = performance.now() - startTime;
    if (DEBUG) console.log(`⚡ FPV Display setup completed in ${setupTime.toFixed(2)}ms`);

    return { fpvRenderer, updateRendererSize };
}

/**
 * @function captureFPVSnapshot
 * @description Captures a snapshot from the FPV display and returns it as a pixel array.
 * @param {THREE.WebGLRenderer} fpvRenderer - The FPV renderer.
 * @returns {Uint8ClampedArray} A pixel array of the FPV snapshot.
 */
export function captureFPVSnapshot(fpvRenderer) {
    const startTime = performance.now();
    
    if (fpvRenderer && fpvRenderer.domElement) {
      const gl = fpvRenderer.getContext();
      const width = fpvRenderer.domElement.width;
      const height = fpvRenderer.domElement.height;
  
      if (DEBUG) console.log('📸 Capturing frame:', { width, height });
  
      if (width === 0 || height === 0) {
        console.error('❌ Canvas dimensions invalid');
        return null;
      }
  
      const pixelData = new Uint8Array(width * height * 4);
      gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixelData);
      
      const captureTime = performance.now() - startTime;
      logPerformance('captureTime', captureTime);
    
      return pixelData;
    } else {
      console.error('❌ FPV Renderer not initialized');
      return null;
    }
}

/**
 * @function downloadImage
 * @description Downloads the image from a base64 data URL.
 * @param {string} dataURL - The base64 data URL of the image.
 * @param {string} filename - The name of the file to be downloaded.
 */
export function downloadImage(dataURL, filename = 'snapshot.png') {
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * @function displayImage
 * @description Displays the image from pixel data in an img element.
 * @param {Uint8ClampedArray} pixelData - The pixel data of the image.
 * @param {number} width - The width of the image.
 * @param {number} height - The height of the image.
 */
export function displayImage(pixelData, width, height) {
  if (!pixelData || pixelData.length !== width * height * 4) {
    console.error('Invalid pixel data or dimensions');
    return;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const imageData = new ImageData(new Uint8ClampedArray(pixelData), width, height);
  ctx.putImageData(imageData, 0, 0);

  const img = document.createElement('img');
  img.src = canvas.toDataURL();
  img.style.position = 'absolute';
  img.style.bottom = '10px';
  img.style.left = '10px';
  img.style.border = '2px solid white';
  img.style.zIndex = '1000'; // Ensure it is on top of other elements
  document.body.appendChild(img);
}

/**
 * @function startFrameCapture
 * @description Starts capturing frames at a specified interval and displays them.
 * @param {THREE.WebGLRenderer} fpvRenderer - The FPV renderer.
 * @param {THREE.Scene} scene - The scene to render.
 * @param {THREE.Camera} camera - The camera to use for rendering.
 * @param {number} interval - The interval in milliseconds between frame captures.
 */
export function startFrameCapture(fpvRenderer, scene, camera, interval = 10000) {
    const captureAndDisplayFrame = async () => {
        // Render the scene with the FPV camera
        fpvRenderer.render(scene, camera);

        // Capture the pixel data directly from the WebGL canvas
        const pixelData = captureFPVSnapshot(fpvRenderer);
        const width = fpvRenderer.domElement.width;
        const height = fpvRenderer.domElement.height;
        if (pixelData) {
            console.log('Pixel data captured:', pixelData);

            // Convert the pixel data directly into a tensor
            const inputTensor = pixelDataToTensor(pixelData, [1, 3, height * 2, width * 2]);
            console.log('Input tensor dimensions:', inputTensor.dims); // Log the dimensions
            console.log('Input tensor:', inputTensor);

            // Run inference on the captured frame
            // try {
            //     const outputData = await runInferenceOnFrameCapture(inputTensor);
            //     console.log('Inference output:', outputData);
            // } catch (error) {
            //     console.error('Error during inference:', error);
            // }
        }
    };

    captureAndDisplayFrame();

    setInterval(captureAndDisplayFrame, interval);
}



/**
 * Runs inference on the captured frame using the worker.
 * @param {Uint8ClampedArray} pixelData - The pixel data from the snapshot.
 * @param {number[]} dims - The dimensions of the tensor.
 */
export function runInference(pixelData, dims, modelName) {
  const startTime = performance.now();
  if (DEBUG) console.log('🤖 Starting inference:', { modelName, dims });
  
  // Get the correct input size from the model config
  const config = modelConfigs[modelName];
  if (!config) {
    console.error('❌ Model config not found:', modelName);
    return;
  }
  
  // Validate dimensions
  const [targetHeight, targetWidth] = config.inputSize;
  if (!pixelData || pixelData.length === 0) {
    console.error('❌ Invalid pixel data');
    return;
  }
  
  // Update dims to match the model's expected input size
  const newDims = [1, 3, targetHeight, targetWidth];
  
  try {
    inferenceWorker.postMessage(
      { 
        type: 'inference', 
        data: { pixelData, dims: newDims, modelName } 
      }, 
      [pixelData.buffer]
    );
    
    const messageTime = performance.now() - startTime;
    if (DEBUG) console.log(`📤 Worker message sent in ${messageTime.toFixed(2)}ms`);
  } catch (error) {
    console.error('❌ Error sending inference message:', error);
  }
}

function updateBoundingBoxes(predictions) {
  if (!boundingBoxOverlay) {
    console.error('❌ Bounding box overlay not initialized');
    return;
  }

  const startTime = performance.now();
  
  // Clear previous boxes
  boundingBoxOverlay.innerHTML = '';

  if (DEBUG) console.log('🎯 Rendering predictions:', predictions);

  // Create classification display
  const resultsDiv = document.createElement('div');
  resultsDiv.style.position = 'absolute';
  resultsDiv.style.top = '10px';
  resultsDiv.style.left = '10px';
  resultsDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
  resultsDiv.style.padding = '10px';
  resultsDiv.style.borderRadius = '5px';
  resultsDiv.style.color = '#fff';
  resultsDiv.style.fontFamily = 'monospace';

  if (Array.isArray(predictions)) {
    predictions.forEach(pred => {
      const predDiv = document.createElement('div');
      predDiv.style.marginBottom = '5px';
      predDiv.textContent = `${pred.class}: ${pred.confidence.toFixed(2)}`;
      resultsDiv.appendChild(predDiv);
    });
  } else {
    const errorDiv = document.createElement('div');
    errorDiv.textContent = 'No valid predictions';
    errorDiv.style.color = '#ff6b6b';
    resultsDiv.appendChild(errorDiv);
  }

  boundingBoxOverlay.appendChild(resultsDiv);

  const updateTime = performance.now() - startTime;
  if (DEBUG) console.log(`🎨 Results updated in ${updateTime.toFixed(2)}ms`);
}
