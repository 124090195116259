/**
 * @class GamepadHandler
 * @description Manages gamepad input for the drone simulation.
 * Handles gamepad connection, disconnection, and provides real-time input data.
 */
class GamepadHandler {
  /**
   * @constructor
   * Initializes the GamepadHandler and sets up the connection visualizer.
   */
  constructor() {
    this.gamepad = null;
    this.connected = false;
    this.axes = [1500, 1500, 1500, 1500]; // [roll, pitch, yaw, throttle]
    this.buttons = [];
    this.reconnectionAttempts = 0;
    this.MAX_RECONNECTION_ATTEMPTS = 5;
    this.hasGivenUp = false;

    this.setupEventListeners();
    this.checkConnection();
  }

  /**
   * @method setupEventListeners
   * @private
   * Sets up event listeners for gamepad connection and disconnection.
   */
  setupEventListeners() {
    window.addEventListener('gamepadconnected', (e) => {
      console.log('🎮 Gamepad connected:', e.gamepad.id);
      this.connected = true;
      this.gamepad = e.gamepad;
    });

    window.addEventListener('gamepaddisconnected', (e) => {
      console.log('🎮 Gamepad disconnected:', e.gamepad.id);
      this.connected = false;
      this.gamepad = null;
      // Reset axes to default values
      this.axes = [1500, 1500, 1500, 1500];
    });
  }

  /**
   * @method checkConnection
   * @private
   * Checks for existing gamepads and establishes connection if available.
   */
  checkConnection() {
    if (this.hasGivenUp) return;
    
    if (this.reconnectionAttempts >= this.MAX_RECONNECTION_ATTEMPTS) {
      console.log('🎮 Switching to keyboard controls');
      this.hasGivenUp = true;
      return;
    }

    const gamepads = navigator.getGamepads();
    for (const gp of gamepads) {
      if (gp) {
        this.connected = true;
        this.gamepad = gp;
        this.reconnectionAttempts = 0;
        this.hasGivenUp = false;
        console.log('🎮 Found existing gamepad:', gp.id);
        return;
      }
    }
    
    this.reconnectionAttempts++;
  }

  /**
   * @method update
   * @public
   * Updates the gamepad state, including axes and button values.
   */
  update() {
    if (this.hasGivenUp) return;
    
    if (!this.connected) {
      if (this.reconnectionAttempts < this.MAX_RECONNECTION_ATTEMPTS) {
        this.checkConnection();
      }
      return;
    }

    try {
      const gamepads = navigator.getGamepads();
      if (!this.gamepad || !gamepads[this.gamepad.index]) {
        if (this.reconnectionAttempts < this.MAX_RECONNECTION_ATTEMPTS) {
          console.log(`🎮 Gamepad lost, attempting reconnection (${this.reconnectionAttempts + 1}/${this.MAX_RECONNECTION_ATTEMPTS})`);
          this.checkConnection();
        }
        return;
      }

      this.gamepad = gamepads[this.gamepad.index];
      this.axes = [
        this.mapAxisToRange(this.gamepad.axes[4]),
        this.mapAxisToRange(this.gamepad.axes[3]),
        this.mapAxisToRange(this.gamepad.axes[0]),
        this.mapAxisToRange(this.gamepad.axes[5]),
      ];
      this.buttons = this.gamepad.buttons.map(button => button.pressed);
    } catch (error) {
      if (!this.hasGivenUp) {
        console.error('🎮 Error updating gamepad:', error);
        this.checkConnection();
      }
    }
  }

  /**
   * @method mapAxisToRange
   * @private
   * @param {number} value - The raw axis value from -1 to 1
   * @returns {number} The mapped value from 0 to 3000
   */
  mapAxisToRange(value) {
    // Map from -1 to 1 range to 0 to 3000 range
    return Math.round((value + 1) * 1500);
  }

  /**
   * @method getAxes
   * @public
   * @returns {number[]} The current axes values
   */
  getAxes() {
    return this.axes;
  }

  /**
   * @method getButtons
   * @public
   * @returns {boolean[]} The current button states
   */
  getButtons() {
    return this.buttons;
  }

  /**
   * @method createConnectionVisualizer
   * @private
   * Creates a visual indicator for gamepad connection status.
   */
  createConnectionVisualizer() {
    this.visualizer = document.createElement('div');
    this.visualizer.style.position = 'absolute';
    this.visualizer.style.top = '10px';
    this.visualizer.style.left = '10px';
    this.visualizer.style.padding = '5px 10px';
    this.visualizer.style.borderRadius = '5px';
    this.visualizer.style.fontFamily = 'Arial, sans-serif';
    this.visualizer.style.fontSize = '14px';
    document.body.appendChild(this.visualizer);
    this.updateConnectionVisualizer();
  }

  /**
   * @method updateConnectionVisualizer
   * @private
   * Updates the visual indicator based on the current connection status.
   */
  updateConnectionVisualizer() {
    this.visualizer.textContent = this.connected ? 'Controller Connected' : 'Controller Disconnected';
    this.visualizer.style.backgroundColor = this.connected ? 'rgba(0, 255, 0, 0.7)' : 'rgba(255, 0, 0, 0.7)';
    this.visualizer.style.color = this.connected ? 'black' : 'white';
  }

  /**
   * @method reconnect
   * @public
   * Forces a reconnection attempt to the gamepad.
   */
  reconnect() {
    if (!this.hasGivenUp && this.reconnectionAttempts < this.MAX_RECONNECTION_ATTEMPTS) {
      console.log(`🎮 Forcing gamepad reconnection (${this.reconnectionAttempts + 1}/${this.MAX_RECONNECTION_ATTEMPTS})`);
      this.checkConnection();
    }
  }
}

export default GamepadHandler;