import React, { useState, useCallback, useRef } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import * as styles from './EnvironmentHub.module.css';
import { useSimulation } from '../context/simulationContext';
import CustomMapModal from './modals/CustomMapModal';

const EnvironmentHub = ({ onClose }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { simulationRef } = useSimulation();

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  const handleCustomMapUpload = async (mapData) => {
    try {
      await simulationRef.current?.loadCustomMap(mapData);
    } catch (error) {
      console.error('Error loading custom map:', error);
      alert('Error loading custom map. Please try again.');
    }
  };

  const handleDefaultEnvironment = async () => {
    try {
      console.log('Loading default environment...');
      
      // Access the scene through sceneRef
      const scene = simulationRef.current?.sceneRef?.current;
      
      if (!scene) {
        console.error('Scene not found');
        return;
      }

      // First remove any custom map if it exists
      if (simulationRef.current?.customMapLoader) {
        console.log('Removing custom map...');
        simulationRef.current.customMapLoader.removeCurrentMap();
      }
      
      // Remove current environment if it exists
      if (scene.environment) {
        console.log('Removing current environment...');
        scene.remove(scene.environment);
        scene.environment = null;
      }
      
      // Load default environment
      console.log('Loading default environment...');
      await scene.loadEnvironment();
      console.log('Default environment loaded');
      
      // Reset drone position
      console.log('Resetting drone position...');
      scene.resetDroneToSpawn();
      console.log('Drone position reset');
      
    } catch (error) {
      console.error('Error loading default environment:', error);
      alert('Error loading default environment. Please try again.');
    }
  };

  return (
    <div className={`${styles.environmentHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>
      
      <div className={styles.customMapSection}>
        <h3>Custom Environment</h3>
        <button 
          className={styles.uploadButton}
          onClick={() => setShowUploadModal(true)}
        >
          Upload Custom Map
        </button>
      </div>

      <div className={styles.environmentList}>
        <div className={styles.environmentCard}>
          <img src="assets/images/environments/default.png" alt="Default Environment" className={styles.environmentImage} />
          <h3>Default Environment</h3>
          <p>Standard training environment with basic obstacles</p>
          <div className={styles.environmentActions}>
            <button className={styles.learnMore}>Learn More</button>
            <button 
              className={styles.select}
              onClick={() => {
                console.log('Select button clicked');
                handleDefaultEnvironment();
              }}
            >
              Select
            </button>
          </div>
        </div>
      </div>
      {showUploadModal && (
        <CustomMapModal
          onClose={() => setShowUploadModal(false)}
          onUpload={handleCustomMapUpload}
        />
      )}
    </div>
  );
};

export default EnvironmentHub; 