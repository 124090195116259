import React, { useContext, useEffect, useState } from 'react';
import Simulation from './components/Simulation';
import ErrorBoundary from './components/ErrorBoundary';
import { LoadingContext, LoadingProvider } from '@/context/loadingContext';
import { SimulationProvider } from './context/simulationContext';
import LoadingScreen from './components/LoadingScreen';
import SimulationHub from './components/SimulationHub';
import * as styles from './App.module.css';

const AppContent = () => {
  const { logs, addLog } = useContext(LoadingContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Initialization steps
    const initialize = async () => {
      try {
        addLog('Starting initialization...');
        
        addLog('Loading Drone Scene...');
        // Simulate Drone Scene initialization
        await new Promise((resolve) => setTimeout(resolve, 2000)); 
        addLog('Drone Scene loaded.');

        addLog('Loading Physics Engine...');
        // Simulate Physics Engine initialization
        await new Promise((resolve) => setTimeout(resolve, 2000)); 
        addLog('Physics Engine loaded.');

        addLog('Loading ONNX Model...');
        // Load the ONNX model
        await new Promise((resolve) => setTimeout(resolve, 2000)); 
        addLog('ONNX Model loaded successfully.');

        // All initialization done
        setIsLoading(false);
        addLog('Initialization complete.');
      } catch (error) {
        addLog(`Error during initialization: ${error.message}`);
        setIsLoading(false);
      }
    };

    initialize();
  }, [addLog]);

  if (isLoading) {
    return <LoadingScreen logs={logs} />;
  }

  return (
    <ErrorBoundary>
      <SimulationProvider>
        <div className={styles.appContainer}>
          <SimulationHub />
          <div className={styles.simulationContent}>
            <Simulation />
          </div>
        </div>
      </SimulationProvider>
    </ErrorBoundary>
  );
};

const App = () => {
  return (
    <LoadingProvider>
      <AppContent />
    </LoadingProvider>
  );
};

export default App;
