import React, { createContext, useContext, useRef, useState } from 'react';

/**
 * @typedef {Object} SimulationContextType
 * @property {React.RefObject} simulationRef - Reference to the simulation instance
 * @property {Function} resetDrone - Function to reset the drone to its initial state
 * @property {boolean} isInferenceRunning - Indicates whether the inference is running
 * @property {string} selectedModel - The name of the selected model
 * @property {Function} toggleInference - Function to toggle the inference state
 */

/**
 * Context for managing drone simulation state and controls across the application.
 * This context provides access to the simulation instance and related control functions.
 * 
 * @type {React.Context<SimulationContextType>}
 */
const SimulationContext = createContext();

/**
 * Provider component for the SimulationContext.
 * Manages the simulation state and provides methods to control the drone simulation.
 * 
 * @component
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components that will have access to the simulation context
 * 
 * @example
 * // Wrap your app or component with SimulationProvider
 * <SimulationProvider>
 *   <YourComponent />
 * </SimulationProvider>
 */
export const SimulationProvider = ({ children }) => {
  // Reference to store the simulation instance
  const simulationRef = useRef(null);
  const [isInferenceRunning, setIsInferenceRunning] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  /**
   * Resets the drone to its initial state and position.
   * This function is called when the simulation needs to be restarted..
   * 
   * @function
   * @throws {Warning} Logs a warning if resetSimulation method is not found
   */
  const resetDrone = () => {
    if (simulationRef.current?.resetSimulation) {
      simulationRef.current.resetSimulation();
    } else {
      console.warn('resetSimulation not found in simulationRef');
    }
  };

  const toggleInference = (modelName) => {
    if (isInferenceRunning) {
      setIsInferenceRunning(false);
      setSelectedModel(null);
    } else {
      setIsInferenceRunning(true);
      setSelectedModel(modelName);
    }
  };

  return (
    <SimulationContext.Provider value={{ 
      simulationRef, 
      resetDrone, 
      isInferenceRunning,
      selectedModel,
      toggleInference 
    }}>
      {children}
    </SimulationContext.Provider>
  );
};

/**
 * Custom hook to access the simulation context.
 * Provides access to simulation controls and state throughout the application.
 * 
 * @function
 * @returns {SimulationContextType} The simulation context value
 * 
 * @example
 * // Using the simulation context in a component
 * const MyComponent = () => {
 *   const { simulationRef, resetDrone } = useSimulation();
 *   
 *   const handleReset = () => {
 *     resetDrone();
 *   };
 *   
 *   return <button onClick={handleReset}>Reset Drone</button>;
 * };
 */
export const useSimulation = () => useContext(SimulationContext);