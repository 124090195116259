import DroneControls from '../controls/droneControls';

class ProgramExecutor {
  constructor(simulationRef) {
    if (!simulationRef?.current) {
      throw new Error('Valid simulation reference required');
    }

    const { physicsEngine, controls, sceneRef } = simulationRef.current;

    if (!physicsEngine || !controls || !sceneRef?.current) {
      throw new Error('Required simulation components not initialized');
    }

    this.simulation = simulationRef.current;
    this.scene = sceneRef.current;
    this.controls = controls;
    this.physicsEngine = physicsEngine;
    
    this.blocks = [];
    this.isRunning = false;
  }

  setBlocks(blocks) {
    this.blocks = blocks;
  }

  async start() {
    if (this.isRunning) return;
    this.isRunning = true;
    console.log('Starting program execution with blocks:', this.blocks);

    for (const block of this.blocks) {
      if (!this.isRunning) break;

      try {
        switch (block.type) {
          case 'takeoff':
            await this.executeCommand('takeoff', block.params?.height || 1.0);
            break;
          case 'land':
            await this.executeCommand('land');
            break;
          case 'hover':
            await this.executeCommand('hover', {
              duration: block.params?.duration || 3.0,
              height: block.params?.height
            });
            break;
          case 'moveForward':
            await this.executeCommand('moveForward', block.params?.duration || 2.0);
            break;
          case 'moveBackward':
            await this.executeCommand('moveBackward', block.params?.duration || 2.0);
            break;
          case 'strafeLeft':
            await this.executeCommand('strafeLeft', block.params?.duration || 2.0);
            break;
          case 'strafeRight':
            await this.executeCommand('strafeRight', block.params?.duration || 2.0);
            break;
          case 'rotateLeft':
            await this.executeCommand('rotateLeft', {
              angle: block.params?.angle || 90,
              duration: block.params?.duration || 2.0
            });
            break;
          case 'rotateRight':
            await this.executeCommand('rotateRight', {
              angle: block.params?.angle || 90,
              duration: block.params?.duration || 2.0
            });
            break;
        }
      } catch (error) {
        console.error('Error executing block:', error);
        this.isRunning = false;
        break;
      }
    }

    // Reset controls when done
    this.stop();
  }

  stop() {
    this.isRunning = false;
    // Reset controls to neutral
    this.controls.setControlInputs({
      roll: 1500,
      pitch: 1500,
      yaw: 1500,
      throttle: 0
    });
  }

  async executeCommand(command, param) {
    console.log(`Executing command: ${command} with param:`, param);
    
    switch (command) {
      case 'takeoff':
        await this.takeoff(param);
        break;
      case 'land':
        await this.land();
        break;
      case 'hover':
        await this.hover(param.duration, param.height);
        break;
      case 'moveForward':
        await this.moveForward(param);
        break;
      case 'moveBackward':
        await this.moveBackward(param);
        break;
      case 'strafeLeft':
        await this.strafeLeft(param);
        break;
      case 'strafeRight':
        await this.strafeRight(param);
        break;
      case 'rotateLeft':
        await this.rotateLeft(param.angle, param.duration);
        break;
      case 'rotateRight':
        await this.rotateRight(param.angle, param.duration);
        break;
    }
  }

  async takeoff(targetHeight) {
    console.log('Starting takeoff to height:', targetHeight);
    const duration = 2000; // 2 seconds
    const startTime = Date.now();
    const startHeight = this.scene.drone.position.y;

    return new Promise((resolve) => {
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        // Calculate required throttle based on height difference
        const currentHeight = this.scene.drone.position.y;
        const heightDiff = targetHeight - currentHeight;
        const throttle = Math.min(Math.max(heightDiff * 0.5 + 0.5, 0), 1);

        // Update controls
        this.controls.setControlInputs({
          roll: 1500,  // Center
          pitch: 1500, // Center
          yaw: 1500,   // Center
          throttle: Math.round(throttle * 3000) // Scale to 0-3000 range
        });

        if (progress < 1 && Math.abs(heightDiff) > 0.1) {
          requestAnimationFrame(animate);
        } else {
          resolve();
        }
      };

      animate();
    });
  }

  async land() {
    console.log('Starting landing sequence');
    const duration = 2000;
    const startTime = Date.now();
    const startHeight = this.scene.drone.position.y;

    return new Promise((resolve) => {
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        // Gradually reduce throttle
        const throttle = (1 - progress) * 0.3; // Gentle landing

        this.controls.setControlInputs({
          roll: 1500,
          pitch: 1500,
          yaw: 1500,
          throttle: Math.round(throttle * 3000)
        });

        if (progress < 1 && this.scene.drone.position.y > 0.1) {
          requestAnimationFrame(animate);
        } else {
          this.stop();
          resolve();
        }
      };

      animate();
    });
  }

  async hover(duration, targetHeight = null) {
    console.log('Starting hover for duration:', duration);
    const startTime = Date.now();
    targetHeight = targetHeight || this.scene.drone.position.y;

    return new Promise((resolve) => {
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        
        const currentHeight = this.scene.drone.position.y;
        const heightDiff = targetHeight - currentHeight;
        const throttle = Math.min(Math.max(heightDiff * 0.5 + 0.5, 0), 1);

        this.controls.setControlInputs({
          roll: 1500,
          pitch: 1500,
          yaw: 1500,
          throttle: Math.round(throttle * 3000)
        });

        if (elapsed < duration * 1000) {
          requestAnimationFrame(animate);
        } else {
          resolve();
        }
      };

      animate();
    });
  }

  async moveForward(duration = 2.0) {
    console.log('Moving forward for duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const TILT_ANGLE = 500;  // Increased from 100 for faster response
      const INITIAL_TILT_TIME = 0.4;  // Decreased from 0.2 for quicker initial tilt
      const RETURN_TO_NEUTRAL_TIME = .15;  // Decreased from 0.3 for faster return
      const BASE_THROTTLE = 900;  // Neutral throttle
      const MOVE_THROTTLE = 800;  // Light constant throttle for movement
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let roll = 1500;  // Default to neutral
        let throttle = BASE_THROTTLE;

        if (elapsed < INITIAL_TILT_TIME) {
          // Initial tilt phase - tilt forward
          const tiltProgress = elapsed / INITIAL_TILT_TIME;
          roll = 1500 + (TILT_ANGLE * Math.sin(tiltProgress * Math.PI / 2));
          throttle = BASE_THROTTLE + (tiltProgress * (MOVE_THROTTLE - BASE_THROTTLE));
        } 
        else if (elapsed < INITIAL_TILT_TIME + RETURN_TO_NEUTRAL_TIME) {
          // Return to neutral tilt while maintaining throttle
          const returnProgress = (elapsed - INITIAL_TILT_TIME) / RETURN_TO_NEUTRAL_TIME;
          roll = 1500 + (TILT_ANGLE * (1 - returnProgress));
          throttle = MOVE_THROTTLE;
        }
        else if (elapsed < duration) {
          // Maintain neutral position with constant throttle
          roll = 1500;
          throttle = MOVE_THROTTLE;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: Math.round(roll),
          pitch: 1500,
          yaw: 1500,
          throttle: Math.round(throttle)
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }

  async moveBackward(duration = 2.0) {
    console.log('Moving backward for duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const TILT_ANGLE = 500;  // Increased from 100 for faster response
      const INITIAL_TILT_TIME = 0.4;  // Decreased from 0.2 for quicker initial tilt
      const RETURN_TO_NEUTRAL_TIME = .15;  // Decreased from 0.3 for faster return
      const BASE_THROTTLE = 900;  // Neutral throttle
      const MOVE_THROTTLE = 800;  // Light constant throttle for movement
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let roll = 1500;  // Default to neutral
        let throttle = BASE_THROTTLE;

        if (elapsed < INITIAL_TILT_TIME) {
          // Initial tilt phase - tilt backward
          const tiltProgress = elapsed / INITIAL_TILT_TIME;
          roll = 1500 - (TILT_ANGLE * Math.sin(tiltProgress * Math.PI / 2));
          throttle = BASE_THROTTLE + (tiltProgress * (MOVE_THROTTLE - BASE_THROTTLE));
        } 
        else if (elapsed < INITIAL_TILT_TIME + RETURN_TO_NEUTRAL_TIME) {
          // Return to neutral tilt while maintaining throttle
          const returnProgress = (elapsed - INITIAL_TILT_TIME) / RETURN_TO_NEUTRAL_TIME;
          roll = 1500 - (TILT_ANGLE * (1 - returnProgress));
          throttle = MOVE_THROTTLE;
        }
        else if (elapsed < duration) {
          // Maintain neutral position with constant throttle
          roll = 1500;
          throttle = MOVE_THROTTLE;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: Math.round(roll),
          pitch: 1500,
          yaw: 1500,
          throttle: Math.round(throttle)
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }

  async strafeLeft(duration = 2.0) {
    console.log('Strafing left for duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const TILT_ANGLE = 500;  // Increased from 100 for faster response
      const INITIAL_TILT_TIME = 0.4;  // Decreased from 0.2 for quicker initial tilt
      const RETURN_TO_NEUTRAL_TIME = .15;  // Decreased from 0.3 for faster return
      const BASE_THROTTLE = 900;  // Neutral throttle
      const MOVE_THROTTLE = 800;  // Light constant throttle for movement
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let pitch = 1500;  // Default to neutral
        let throttle = BASE_THROTTLE;

        if (elapsed < INITIAL_TILT_TIME) {
          // Initial tilt phase - tilt left
          const tiltProgress = elapsed / INITIAL_TILT_TIME;
          pitch = 1500 - (TILT_ANGLE * Math.sin(tiltProgress * Math.PI / 2));
          throttle = BASE_THROTTLE + (tiltProgress * (MOVE_THROTTLE - BASE_THROTTLE));
        } 
        else if (elapsed < INITIAL_TILT_TIME + RETURN_TO_NEUTRAL_TIME) {
          // Return to neutral tilt while maintaining throttle
          const returnProgress = (elapsed - INITIAL_TILT_TIME) / RETURN_TO_NEUTRAL_TIME;
          pitch = 1500 - (TILT_ANGLE * (1 - returnProgress));
          throttle = MOVE_THROTTLE;
        }
        else if (elapsed < duration) {
          // Maintain neutral position with constant throttle
          pitch = 1500;
          throttle = MOVE_THROTTLE;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: 1500,
          pitch: Math.round(pitch),
          yaw: 1500,
          throttle: Math.round(throttle)
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }

  async strafeRight(duration = 2.0) {
    console.log('Strafing right for duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const TILT_ANGLE = 500;  // Increased from 100 for faster response
      const INITIAL_TILT_TIME = 0.4;  // Decreased from 0.2 for quicker initial tilt
      const RETURN_TO_NEUTRAL_TIME = .15;  // Decreased from 0.3 for faster return
      const BASE_THROTTLE = 900;  // Neutral throttle
      const MOVE_THROTTLE = 800;  // Light constant throttle for movement
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let pitch = 1500;  // Default to neutral
        let throttle = BASE_THROTTLE;

        if (elapsed < INITIAL_TILT_TIME) {
          // Initial tilt phase - tilt right
          const tiltProgress = elapsed / INITIAL_TILT_TIME;
          pitch = 1500 + (TILT_ANGLE * Math.sin(tiltProgress * Math.PI / 2));
          throttle = BASE_THROTTLE + (tiltProgress * (MOVE_THROTTLE - BASE_THROTTLE));
        } 
        else if (elapsed < INITIAL_TILT_TIME + RETURN_TO_NEUTRAL_TIME) {
          // Return to neutral tilt while maintaining throttle
          const returnProgress = (elapsed - INITIAL_TILT_TIME) / RETURN_TO_NEUTRAL_TIME;
          pitch = 1500 + (TILT_ANGLE * (1 - returnProgress));
          throttle = MOVE_THROTTLE;
        }
        else if (elapsed < duration) {
          // Maintain neutral position with constant throttle
          pitch = 1500;
          throttle = MOVE_THROTTLE;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: 1500,
          pitch: Math.round(pitch),
          yaw: 1500,
          throttle: Math.round(throttle)
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }

  async rotateLeft(angle = 90, duration = 2.0) {
    console.log('Rotating left with angle:', angle, 'duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const YAW_RATE = 850; // Reduced rate for more precise control
      const BASE_THROTTLE = 900;
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let yaw = 1500;  // Default to neutral

        if (elapsed < duration) {
          // Calculate progress as a percentage of the duration
          const progress = elapsed / duration;
          // Apply a sine easing function for smoother start/stop
          const easing = Math.sin(progress * Math.PI / 2);
          // Calculate yaw based on progress
          yaw = 1500 - YAW_RATE * (angle / 90) * easing;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: 1500,
          pitch: 1500,
          yaw: Math.round(yaw),
          throttle: BASE_THROTTLE
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }

  async rotateRight(angle = 90, duration = 2.0) {
    console.log('Rotating right with angle:', angle, 'duration:', duration);
    return new Promise((resolve) => {
      const startTime = Date.now();
      const YAW_RATE = 850; // Reduced rate for more precise control
      const BASE_THROTTLE = 900;
      
      const animate = () => {
        if (!this.isRunning) {
          this.stop();
          resolve();
          return;
        }

        const elapsed = (Date.now() - startTime) / 1000;
        let yaw = 1500;  // Default to neutral

        if (elapsed < duration) {
          // Calculate progress as a percentage of the duration
          const progress = elapsed / duration;
          // Apply a sine easing function for smoother start/stop
          const easing = Math.sin(progress * Math.PI / 2);
          // Calculate yaw based on progress
          yaw = 1500 + YAW_RATE * (angle / 90) * easing;
        }

        // Apply controls
        this.controls.setControlInputs({
          roll: 1500,
          pitch: 1500,
          yaw: Math.round(yaw),
          throttle: BASE_THROTTLE
        });

        if (elapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          // Reset to neutral position
          this.controls.setControlInputs({
            roll: 1500,
            pitch: 1500,
            yaw: 1500,
            throttle: BASE_THROTTLE
          });
          resolve();
        }
      };

      animate();
    });
  }
}

export default ProgramExecutor; 