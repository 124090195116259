import React from 'react';
import * as styles from './LoadingMapScreen.module.css';

const LoadingMapScreen = ({ progress, currentTask }) => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.glowBackground}></div>
      
      <div className={styles.loadingContent}>
        <div className={styles.header}>
          <h1 className={styles.title}>FORGE MAP</h1>
          <div className={styles.progressIndicator}>
            <div className={styles.progressText}>{Math.round(progress)}%</div>
            <div className={styles.taskText}>{currentTask}</div>
          </div>
        </div>

        <div className={styles.loadingBarContainer}>
          <div 
            className={styles.loadingBar} 
            style={{ width: `${progress}%`, transition: 'width 0.3s ease-out' }}
          ></div>
        </div>

        <div className={styles.stepsContainer}>
          <div className={styles.loadingSteps}>
            <div className={`${styles.step} ${progress >= 25 ? styles.completed : ''}`}>
              <div className={styles.stepIcon}>
                <div className={styles.stepNumber}>1</div>
                <svg className={styles.checkmark} viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
                </svg>
              </div>
              <div className={styles.stepContent}>
                <div className={styles.stepTitle}>Loading Files</div>
                <div className={styles.stepDescription}>Importing map data</div>
              </div>
            </div>

            <div className={`${styles.step} ${progress >= 50 ? styles.completed : ''}`}>
              <div className={styles.stepIcon}>
                <div className={styles.stepNumber}>2</div>
                <svg className={styles.checkmark} viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
                </svg>
              </div>
              <div className={styles.stepContent}>
                <div className={styles.stepTitle}>Processing Geometry</div>
                <div className={styles.stepDescription}>Optimizing mesh data</div>
              </div>
            </div>

            <div className={`${styles.step} ${progress >= 75 ? styles.completed : ''}`}>
              <div className={styles.stepIcon}>
                <div className={styles.stepNumber}>3</div>
                <svg className={styles.checkmark} viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
                </svg>
              </div>
              <div className={styles.stepContent}>
                <div className={styles.stepTitle}>Creating Physics</div>
                <div className={styles.stepDescription}>Building collision mesh</div>
              </div>
            </div>

            <div className={`${styles.step} ${progress >= 100 ? styles.completed : ''}`}>
              <div className={styles.stepIcon}>
                <div className={styles.stepNumber}>4</div>
                <svg className={styles.checkmark} viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
                </svg>
              </div>
              <div className={styles.stepContent}>
                <div className={styles.stepTitle}>Finalizing</div>
                <div className={styles.stepDescription}>Preparing environment</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMapScreen;