import React, { useState, useCallback } from 'react';
import { FaArrowLeft, FaRobot, FaChartLine, FaCog, FaTrophy, FaPlay, FaStop, FaPause } from 'react-icons/fa';
import * as styles from './RLHub.module.css';

const SUPPORTED_REWARDS = {
  distanceToGoal: { label: 'Distance to Goal', defaultWeight: 1.0 },
  controlEffort: { label: 'Control Effort', defaultWeight: -0.3 },
  stability: { label: 'Stability', defaultWeight: 0.5 },
  collision: { label: 'Collision Penalty', defaultWeight: -1.0 },
  taskCompletion: { label: 'Task Completion', defaultWeight: 1.0 },
  energyEfficiency: { label: 'Energy Efficiency', defaultWeight: 0.2 }
};

const ALGORITHM_PARAMS = {
  PPO: {
    learningRate: { default: 0.0003, min: 0.00001, max: 0.001 },
    batchSize: { default: 64, min: 32, max: 256 },
    clipRange: { default: 0.2, min: 0.1, max: 0.5 }
  },
  SAC: {
    learningRate: { default: 0.0003, min: 0.00001, max: 0.001 },
    batchSize: { default: 256, min: 64, max: 512 },
    tau: { default: 0.005, min: 0.001, max: 0.01 }
  },
  TD3: {
    learningRate: { default: 0.0003, min: 0.00001, max: 0.001 },
    batchSize: { default: 100, min: 64, max: 256 },
    policyDelay: { default: 2, min: 1, max: 4 }
  }
};

const RLHub = ({ onClose, onTrainingToggle, isTraining }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState('PPO');
  const [rewardWeights, setRewardWeights] = useState(
    Object.entries(SUPPORTED_REWARDS).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value.defaultWeight
    }), {})
  );
  const [hyperParams, setHyperParams] = useState(
    Object.entries(ALGORITHM_PARAMS.PPO).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value.default
    }), {})
  );
  const [trainingMetrics, setTrainingMetrics] = useState({
    episodeReward: 0,
    successRate: 0,
    steps: 0
  });

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  const handleAlgorithmChange = (algo) => {
    setSelectedAlgorithm(algo);
    setHyperParams(
      Object.entries(ALGORITHM_PARAMS[algo]).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value.default
      }), {})
    );
  };

  const handleTrainingToggle = () => {
    onTrainingToggle({
      algorithm: selectedAlgorithm,
      rewardWeights,
      hyperParams
    });
  };

  const updateRewardWeight = (key, value) => {
    setRewardWeights(prev => ({
      ...prev,
      [key]: parseFloat(value)
    }));
  };

  return (
    <div className={`${styles.rlHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>

      <div className={styles.content}>
        <div className={styles.section}>
          <h3><FaRobot /> Algorithm</h3>
          <div className={styles.algorithmList}>
            {Object.keys(ALGORITHM_PARAMS).map(algo => (
              <button 
                key={algo}
                className={`${styles.algorithmButton} ${selectedAlgorithm === algo ? styles.active : ''}`}
                onClick={() => handleAlgorithmChange(algo)}
              >
                {algo}
              </button>
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <h3><FaTrophy /> Reward Components</h3>
          <div className={styles.rewardList}>
            {Object.entries(SUPPORTED_REWARDS).map(([key, { label }]) => (
              <div key={key} className={styles.rewardItem}>
                <label className={styles.rewardLabel}>{label}</label>
                <div className={styles.sliderContainer}>
                  <input
                    type="range"
                    min="-1"
                    max="1"
                    step="0.1"
                    value={rewardWeights[key]}
                    onChange={(e) => updateRewardWeight(key, e.target.value)}
                    className={styles.rewardWeight}
                  />
                  <span className={styles.weightValue}>{rewardWeights[key].toFixed(1)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <h3><FaCog /> Parameters</h3>
          <div className={styles.paramGrid}>
            {Object.entries(ALGORITHM_PARAMS[selectedAlgorithm]).map(([key, config]) => (
              <div key={key} className={styles.paramItem}>
                <label>{key.replace(/([A-Z])/g, ' $1').trim()}</label>
                <input
                  type="number"
                  value={hyperParams[key]}
                  onChange={(e) => setHyperParams(prev => ({
                    ...prev,
                    [key]: parseFloat(e.target.value)
                  }))}
                  step={key === 'learningRate' ? '0.00001' : '1'}
                  min={config.min}
                  max={config.max}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <h3><FaChartLine /> Metrics</h3>
          <div className={styles.metricGrid}>
            <div className={styles.metricCard}>
              <span>Episode Reward</span>
              <div className={styles.metricValue}>{trainingMetrics.episodeReward.toFixed(2)}</div>
            </div>
            <div className={styles.metricCard}>
              <span>Success Rate</span>
              <div className={styles.metricValue}>{(trainingMetrics.successRate * 100).toFixed(1)}%</div>
            </div>
            <div className={styles.metricCard}>
              <span>Steps</span>
              <div className={styles.metricValue}>{trainingMetrics.steps}</div>
            </div>
          </div>
        </div>

        <div className={styles.controls}>
          <button 
            className={`${styles.controlButton} ${isTraining ? styles.active : ''}`}
            onClick={handleTrainingToggle}
          >
            {isTraining ? <><FaStop /> Stop</> : <><FaPlay /> Start</>}
          </button>
          <button 
            className={styles.controlButton}
            disabled={!isTraining}
          >
            <FaPause />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RLHub; 