import * as THREE from 'three';

/**
 * Creates a position display element.
 * @returns {HTMLElement} The DOM element for displaying position.
 */
export const createPositionDisplay = () => {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.bottom = '130px';
  container.style.left = '20px';
  container.style.padding = '15px';
  container.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
  container.style.color = '#00F135';
  container.style.fontFamily = 'Arial, sans-serif';
  container.style.borderRadius = '8px';
  container.style.border = '1px solid rgba(0, 241, 53, 0.2)';
  container.style.boxShadow = '0 0 20px rgba(0, 241, 53, 0.1)';
  container.style.minWidth = '200px';

  // Position display
  const positionDiv = document.createElement('div');
  positionDiv.style.display = 'flex';
  positionDiv.style.alignItems = 'center';
  positionDiv.style.justifyContent = 'space-between';
  positionDiv.style.padding = '5px 0';
  positionDiv.style.borderBottom = '1px solid rgba(0, 241, 53, 0.1)';

  const positionText = document.createElement('span');
  positionText.style.fontSize = '0.9rem';
  positionText.style.fontWeight = 'bold';
  positionText.innerText = 'Position: (0, 0, 0)';

  const editButton = document.createElement('button');
  editButton.innerText = 'Edit';
  editButton.style.backgroundColor = 'rgba(0, 241, 53, 0.1)';
  editButton.style.border = '1px solid #00F135';
  editButton.style.color = '#00F135';
  editButton.style.padding = '4px 8px';
  editButton.style.borderRadius = '4px';
  editButton.style.cursor = 'pointer';
  editButton.style.marginLeft = '10px';
  editButton.style.fontSize = '0.8rem';

  positionDiv.appendChild(positionText);
  positionDiv.appendChild(editButton);
  container.appendChild(positionDiv);

  // Position editor (initially hidden)
  const editorDiv = document.createElement('div');
  editorDiv.style.display = 'none';
  editorDiv.style.marginTop = '10px';
  editorDiv.style.padding = '10px';
  editorDiv.style.backgroundColor = 'rgba(0, 241, 53, 0.05)';
  editorDiv.style.borderRadius = '4px';

  const createInput = (label, defaultValue = '0') => {
    const wrapper = document.createElement('div');
    wrapper.style.marginBottom = '8px';
    wrapper.style.display = 'flex';
    wrapper.style.alignItems = 'center';
    wrapper.style.justifyContent = 'space-between';
    wrapper.style.padding = '5px 0';
    wrapper.style.borderBottom = '1px solid rgba(0, 241, 53, 0.1)';

    const labelElement = document.createElement('label');
    labelElement.innerText = label;
    labelElement.style.fontSize = '0.9rem';
    labelElement.style.fontWeight = 'bold';
    labelElement.style.marginRight = '10px';

    const input = document.createElement('input');
    input.type = 'number';
    input.value = defaultValue;
    input.step = '0.1';
    input.style.width = '80px';
    input.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
    input.style.border = '1px solid #00F135';
    input.style.color = '#00F135';
    input.style.padding = '4px';
    input.style.borderRadius = '4px';
    input.style.fontSize = '0.9rem';

    wrapper.appendChild(labelElement);
    wrapper.appendChild(input);
    return { wrapper, input };
  };

  const xInput = createInput('X:');
  const yInput = createInput('Y:', '5');  // Default drone height
  const zInput = createInput('Z:');

  editorDiv.appendChild(xInput.wrapper);
  editorDiv.appendChild(yInput.wrapper);
  editorDiv.appendChild(zInput.wrapper);

  const buttonDiv = document.createElement('div');
  buttonDiv.style.display = 'flex';
  buttonDiv.style.gap = '8px';
  buttonDiv.style.marginTop = '10px';

  const applyButton = document.createElement('button');
  applyButton.innerText = 'Apply';
  applyButton.style.backgroundColor = '#00F135';
  applyButton.style.color = 'black';
  applyButton.style.border = 'none';
  applyButton.style.padding = '6px 12px';
  applyButton.style.borderRadius = '4px';
  applyButton.style.cursor = 'pointer';
  applyButton.style.flex = '1';

  const cancelButton = document.createElement('button');
  cancelButton.innerText = 'Cancel';
  cancelButton.style.backgroundColor = 'transparent';
  cancelButton.style.border = '1px solid #00F135';
  cancelButton.style.color = '#00F135';
  cancelButton.style.padding = '6px 12px';
  cancelButton.style.borderRadius = '4px';
  cancelButton.style.cursor = 'pointer';
  cancelButton.style.flex = '1';

  buttonDiv.appendChild(cancelButton);
  buttonDiv.appendChild(applyButton);
  editorDiv.appendChild(buttonDiv);
  container.appendChild(editorDiv);

  // Toggle editor visibility
  editButton.addEventListener('click', () => {
    const isVisible = editorDiv.style.display === 'block';
    editorDiv.style.display = isVisible ? 'none' : 'block';
    editButton.innerText = isVisible ? 'Edit' : 'Close';
  });

  // Hide editor on cancel
  cancelButton.addEventListener('click', () => {
    editorDiv.style.display = 'none';
    editButton.innerText = 'Edit';
  });

  return {
    container,
    positionText,
    applyButton,
    getInputValues: () => ({
      x: parseFloat(xInput.input.value),
      y: parseFloat(yInput.input.value),
      z: parseFloat(zInput.input.value)
    })
  };
};

/**
 * Updates the position display with the current drone position.
 * @param {HTMLElement} positionDisplay - The position display element.
 * @param {THREE.Vector3} position - The drone's current position.
 */
export const updatePositionDisplay = (positionDisplay, position) => {
  if (positionDisplay && position) {
    positionDisplay.positionText.innerText = 
      `Position: (${position.x.toFixed(2)}, ${position.y.toFixed(2)}, ${position.z.toFixed(2)})`;
  }
};

/**
 * Creates a control bar for displaying channel values.
 * @param {string} name - The name of the control bar.
 * @param {number} value - The initial value.
 * @param {number} min - The minimum value.
 * @param {number} max - The maximum value.
 * @returns {Object} The control bar object containing the container and value element.
 */
export const createControlBar = (name, value, min, max) => {
  const container = document.createElement('div');
  container.style.display = 'flex';
  container.style.alignItems = 'center';
  container.style.justifyContent = 'space-between';
  container.style.padding = '5px 0';
  container.style.borderBottom = '1px solid rgba(0, 241, 53, 0.1)';

  const label = document.createElement('span');
  label.innerText = `${name}:`;
  label.style.fontSize = '0.9rem';
  label.style.fontWeight = 'bold';
  container.appendChild(label);

  const valueSpan = document.createElement('span');
  valueSpan.innerText = Math.round(value);
  valueSpan.style.fontSize = '0.9rem';
  valueSpan.style.fontFamily = 'monospace';
  valueSpan.style.padding = '2px 6px';
  valueSpan.style.backgroundColor = 'rgba(0, 241, 53, 0.1)';
  valueSpan.style.borderRadius = '4px';
  container.appendChild(valueSpan);

  return { container, valueSpan };
};

/**
 * Updates the control bar with the current channel value.
 * @param {Object} controlBar - The control bar object.
 * @param {number} value - The current value of the channel.
 */
export const updateControlBar = (controlBar, value) => {
  if (controlBar && controlBar.valueSpan) {
    controlBar.valueSpan.innerText = Math.round(value);
  }
};

/**
 * Creates performance stats using Stats.js.
 * @returns {Stats} The Stats instance.
 */
export const createPerformanceStats = () => {
  const Stats = require('stats.js');
  const stats = new Stats();
  stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
  stats.dom.style.position = 'absolute';
  stats.dom.style.top = '0px';
  stats.dom.style.left = '0px';
  stats.dom.style.zIndex = '100'; // Ensure it's above other elements
  return stats;
};

/**
 * Creates a compass element.
 * @returns {HTMLElement} The compass element.
 */
export const createCompass = () => {
  const compassDiv = document.createElement('div');
  compassDiv.style.position = 'absolute';
  compassDiv.style.top = '10px';
  compassDiv.style.left = '50%';
  compassDiv.style.transform = 'translateX(-50%)';
  compassDiv.style.width = '100px';
  compassDiv.style.height = '100px';
  compassDiv.style.border = '2px solid white';
  compassDiv.style.borderRadius = '50%';
  compassDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  compassDiv.style.display = 'flex';
  compassDiv.style.alignItems = 'center';
  compassDiv.style.justifyContent = 'center';
  compassDiv.style.color = 'white';
  compassDiv.style.fontFamily = 'Arial, sans-serif';
  compassDiv.innerText = 'Compass';
  return compassDiv;
};

/**
 * Updates the compass based on drone's orientation.
 * @param {HTMLElement} compass - The compass element.
 * @param {THREE.Quaternion} quaternion - The drone's current orientation.
 */
export const updateCompass = (compass, quaternion) => {
  if (compass && quaternion) {
    const euler = new THREE.Euler().setFromQuaternion(quaternion);
    const yaw = THREE.MathUtils.radToDeg(euler.y);
    compass.innerText = `Yaw: ${yaw.toFixed(2)}°`;
  }
};

/**
 * Creates and initializes the axes view for showing drone orientation.
 * @param {HTMLElement} mountElement - The element to mount the axes renderer to.
 * @returns {Object} An object containing the axes renderer, scene, camera, and helper.
 */
export const createAxesView = (mountElement) => {
  const axesRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  axesRenderer.setSize(100, 100);
  axesRenderer.domElement.style.position = 'absolute';
  axesRenderer.domElement.style.bottom = '10px';
  axesRenderer.domElement.style.left = '10px';
  mountElement.appendChild(axesRenderer.domElement);

  const axesScene = new THREE.Scene();
  const axesCamera = new THREE.PerspectiveCamera(10, 1, 0.1, 10);
  axesCamera.position.set(0, 0, 3);
  axesCamera.lookAt(0, 0, 0);
  const axesHelper = new THREE.AxesHelper(2);
  axesScene.add(axesHelper);

  return { axesRenderer, axesScene, axesCamera, axesHelper };
};

/**
 * Updates the axes view based on the drone's orientation.
 * @param {Object} axesView - The axes view object returned by createAxesView.
 * @param {THREE.Quaternion} quaternion - The drone's current orientation.
 */
export const updateAxesView = (axesView, quaternion) => {
  if (axesView && quaternion) {
    axesView.axesScene.quaternion.copy(quaternion);
    axesView.axesRenderer.render(axesView.axesScene, axesView.axesCamera);
  }
};
