import React, { useRef, useEffect, useContext, useState } from 'react';
import * as THREE from 'three';
import DroneScene from '../scenes/droneScene';
import DroneControls from '../controls/droneControls';
import PhysicsEngine from '../physics/PhysicsEngine';
import {
  createPositionDisplay,
  updatePositionDisplay,
  createControlBar,
  updateControlBar,
  createCompass,
  updateCompass,
  createAxesView,
  updateAxesView,
} from '../utils/helperFunctions';
import {
  createFPVDisplay,
  captureFPVSnapshot,
  downloadImage,
  displayImage,
  startFrameCapture,
  runInference,
} from '../utils/fpvUtils';
import { LoadingContext } from '../context/loadingContext';
import { useSimulation } from '../context/simulationContext';
import CustomMapLoader from '../utils/customMapLoader';

/**
 * @component Simulation
 * @description A React component that renders a 3D drone simulation using Three.js.
 * It includes a main view, First Person View (FPV), axes display, and various UI elements
 * for displaying drone status and controls.
 * 
 * @example
 * <Simulation />
 */
const Simulation = () => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const lastTimeRef = useRef(performance.now());
  const fpsRef = useRef(0);
  const frameTimesRef = useRef([]);
  const physicsTimeRef = useRef(0);
  const renderTimeRef = useRef(0);
  const fpvCameraRef = useRef(null);
  const fpvRendererRef = useRef(null);
  const { addLog } = useContext(LoadingContext);
  const { simulationRef, isInferenceRunning, selectedModel } = useSimulation();
  const physicsEngineRef = useRef(null);
  const controlsRef = useRef(null);
  const controlBarsRef = useRef(null);

  // ===================NEW===================


  const [isHorizonMode, setIsHorizonMode] = useState(false);
  const [fpvReady, setFpvReady] = useState(false);

  // ===================NEW===================


  const resetSimulation = () => {
    if (sceneRef.current) {
      const success = sceneRef.current.resetDroneToSpawn();
      
      // Ensure physics engine is still active
      if (success && physicsEngineRef.current) {
        physicsEngineRef.current.update();
      }
      
      if (success) {
        addLog('Drone reset to initial position');
      }
    }
  };

  useEffect(() => {
    if (!mountRef.current) return;

    /**
     * Initializes the Three.js renderer and sets up the main scene.
     * @type {THREE.WebGLRenderer}
     */
    addLog('Initializing Three.js Renderer...');
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.appendChild(renderer.domElement);
    addLog('Three.js Renderer initialized.');

    /**
     * Sets up the main camera for the drone scene.
     * @type {THREE.PerspectiveCamera}
     */
    addLog('Initializing Drone Scene...');
    const scene = new DroneScene(addLog);
    sceneRef.current = scene;
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 1, -3);
    camera.lookAt(0, 0, 0);
    addLog('Drone Scene and Camera initialized.');

    /**
     * Initializes drone controls and physics engine.
     * @type {DroneControls}
     * @type {PhysicsEngine}
     */
    addLog('Initializing Drone Controls...');
    const controls = new DroneControls();
    controlsRef.current = controls;
    addLog('Drone Controls initialized.');

    addLog('Initializing Physics Engine...');
    const physicsEngine = new PhysicsEngine(controls);
    physicsEngineRef.current = physicsEngine;
    addLog('Physics Engine initialized.');

    // Initialize the scene and physics engine
    scene.init().then(() => {
      addLog('Drone Scene initialized successfully.');
      physicsEngine.init(scene).then(() => {
        addLog('Physics Engine initialized successfully.');
      }).catch((error) => {
        addLog(`Error initializing Physics Engine: ${error.message}`);
      });
    }).catch((error) => {
      addLog(`Error initializing Drone Scene: ${error.message}`);
    });

    /**
     * Creates and sets up various UI elements for the simulation.
     */
    addLog('Creating UI Elements...');
    const positionDisplay = createPositionDisplay();
    mountRef.current.appendChild(positionDisplay.container);
    addLog('Position Display created.');

    // Initialize FPV display
    const { fpvRenderer, updateRendererSize } = createFPVDisplay(mountRef.current);
    fpvRendererRef.current = fpvRenderer;

    // Create FPV camera
    const fpvCamera = new THREE.PerspectiveCamera(
      90,
      fpvRenderer.domElement.width / fpvRenderer.domElement.height,
      0.1,
      1000
    );
    fpvCameraRef.current = fpvCamera;

    // Signal that FPV is ready
    setFpvReady(true);

    /**
     * Creates and sets up the axes view for showing drone orientation.
     */
    addLog('Creating Axes View...');
    const axesView = createAxesView(mountRef.current);
    addLog('Axes View created.');

    /**
     * Creates control display for showing drone control inputs.
     */
    const controlDisplay = document.createElement('div');
    controlDisplay.style.position = 'absolute';
    controlDisplay.style.bottom = '20px';
    controlDisplay.style.right = '20px';
    controlDisplay.style.padding = '15px';
    controlDisplay.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
    controlDisplay.style.color = '#00F135';
    controlDisplay.style.fontFamily = 'Arial, sans-serif';
    controlDisplay.style.borderRadius = '8px';
    controlDisplay.style.border = '1px solid rgba(0, 241, 53, 0.2)';
    controlDisplay.style.boxShadow = '0 0 20px rgba(0, 241, 53, 0.1)';
    controlDisplay.style.minWidth = '150px';
    const controlBars = {
      roll: createControlBar('Roll', 1500, 0, 3000),
      pitch: createControlBar('Pitch', 1500, 0, 3000),
      yaw: createControlBar('Yaw', 1500, 0, 3000),
      throttle: createControlBar('Throttle', 0, 0, 3000)
    };
    controlBarsRef.current = controlBars;
    Object.values(controlBars).forEach(bar => controlDisplay.appendChild(bar.container));
    mountRef.current.appendChild(controlDisplay);

    const compass = createCompass();
    mountRef.current.appendChild(compass);
    addLog('Compass created.');

    /**
     * Handles window resize events to update camera and renderer.
     */
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      updateRendererSize(); // Update FPV renderer size
    };

    window.addEventListener('resize', handleResize);

    const updateFPS = () => {
      const now = performance.now();
      const frameTimes = frameTimesRef.current;
      
      // Add current frame time
      frameTimes.push(now);
      
      // Remove frames older than 1 second
      while (frameTimes[0] <= now - 1000) {
        frameTimes.shift();
      }
      
      // Calculate FPS
      fpsRef.current = frameTimes.length;
      
      // Update global metrics
      window.metrics = {
        fps: fpsRef.current,
        physicsTime: physicsTimeRef.current,
        renderTime: renderTimeRef.current,
        memory: performance.memory?.usedJSHeapSize || 0
      };
    };

    /**
     * Main animation loop for the simulation.
     * @param {number} time - The current timestamp.
     */
    const animate = (time) => {
      const now = performance.now();
      const deltaTime = (now - lastTimeRef.current) / 1000;
      lastTimeRef.current = now;

      // Update controls first
      if (controlsRef.current) {
        controlsRef.current.update();
      }

      // Update FPS calculation
      const frameTimes = frameTimesRef.current;
      frameTimes.push(now);
      while (frameTimes[0] <= now - 1000) {
        frameTimes.shift();
      }
      fpsRef.current = frameTimes.length;

      // Start timing physics
      const physicsStart = performance.now();
      if (physicsEngineRef.current) {
        physicsEngineRef.current.update(deltaTime);
      }
      const physicsEnd = performance.now();
      physicsTimeRef.current = physicsEnd - physicsStart;

      // Update scene animations
      if (sceneRef.current) {
        sceneRef.current.update(deltaTime);
      }

      // Update camera positions
      if (sceneRef.current?.drone) {
        updateCameraPosition(camera, sceneRef.current.drone);
        if (fpvCameraRef.current) {
          updateFPVCameraPosition(fpvRendererRef.current, sceneRef.current.drone, fpvCameraRef.current);
        }
      }

      // Start timing render
      const renderStart = performance.now();
      renderer.render(sceneRef.current, camera);
      if (fpvRendererRef.current && fpvCameraRef.current) {
        fpvRendererRef.current.render(sceneRef.current, fpvCameraRef.current);
      }
      const renderEnd = performance.now();
      renderTimeRef.current = renderEnd - renderStart;

      // Update metrics
      window.metrics = {
        fps: fpsRef.current,
        physicsTime: physicsTimeRef.current,
        renderTime: renderTimeRef.current,
        memory: performance.memory?.usedJSHeapSize || 0
      };

      // Update UI displays
      if (sceneRef.current?.drone) {
        updatePositionDisplay(positionDisplay, sceneRef.current.drone.position);
        if (controlsRef.current && controlBarsRef.current) {
          updateControlBarsDisplay(controlBarsRef.current, controlsRef.current.getControlInputs());
        }
        updateCompass(compass, sceneRef.current.drone.quaternion);
        updateAxesView(axesView, sceneRef.current.drone.quaternion);
      }

      requestAnimationFrame(animate);
    };

    animate();
    addLog('Animation loop started.');

    // Initialize CustomMapLoader
    const customMapLoader = new CustomMapLoader(scene, physicsEngine);

    // Add loadCustomMap method to simulationRef
    simulationRef.current = {
      resetSimulation,
      loadCustomMap: async (file) => {
        try {
          await customMapLoader.loadCustomMap(file);
          addLog('Custom map loaded successfully');
        } catch (error) {
          addLog(`Error loading custom map: ${error.message}`);
          throw error;
        }
      },
      sceneRef: sceneRef,
      physicsEngine: physicsEngineRef.current,
      controls: controlsRef.current,
      customMapLoader
    };

    // Add position update handler
    positionDisplay.applyButton.addEventListener('click', () => {
      const newPosition = positionDisplay.getInputValues();
      if (sceneRef.current?.drone) {
        const success = sceneRef.current.setDronePosition(
          new THREE.Vector3(newPosition.x, newPosition.y, newPosition.z)
        );
        if (success) {
          // Force sync physics after position update
          const drone = sceneRef.current.drone;
          const physicsBody = drone.userData.physicsBody;
          if (physicsBody && physicsEngineRef.current) {
            physicsEngineRef.current.syncPhysicsWithVisual(drone, physicsBody);
          }
          addLog(`Drone position updated to (${newPosition.x}, ${newPosition.y}, ${newPosition.z})`);
        }
      }
    });

    /**
     * Captures FPV snapshots and sends them for inference when inference is running.
     */
    const startInferencePipeline = () => {
      if (!isInferenceRunning || !selectedModel) return;

      const captureAndInfer = () => {
        // Render the scene with FPV camera
        fpvRenderer.render(scene, camera);

        // Capture the snapshot
        const pixelData = captureFPVSnapshot(fpvRenderer);
        const width = fpvRenderer.domElement.width;
        const height = fpvRenderer.domElement.height;

        if (pixelData) {
          console.log('Pixel data captured:', pixelData);

          // Convert pixel data to tensor dimensions [1, 3, H, W]
          const dims = [1, 3, height, width];

          // Run inference
          runInference(pixelData, dims, selectedModel);
        }
      };

      // Capture and infer immediately
      captureAndInfer();

      // Set interval for continuous inference
      const intervalId = setInterval(captureAndInfer, 1000); // Adjust interval as needed

      return () => clearInterval(intervalId);
    };

    // Start the inference pipeline when runningInference is active
    let inferenceCleanup = null;
    if (isInferenceRunning) {
      inferenceCleanup = startInferencePipeline();
    }

    // ===================NEW===================

    // Cleanup inference pipeline when inference is deactivated
    return () => {
      if (inferenceCleanup) inferenceCleanup();
      window.removeEventListener('resize', handleResize);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
        mountRef.current.removeChild(positionDisplay.container);
        mountRef.current.removeChild(controlDisplay);
        mountRef.current.removeChild(compass);
        mountRef.current.removeChild(axesView.axesRenderer.domElement);
        const fpvDisplay = mountRef.current.querySelector('div');
        if (fpvDisplay) {
          mountRef.current.removeChild(fpvDisplay);
        }
      }
      addLog('Cleanup completed on unmount.');
      console.log('Cleaning up Simulation component');
      simulationRef.current = null;
      sceneRef.current = null;
      physicsEngineRef.current = null;
      customMapLoader.removeCurrentMap();
      frameTimesRef.current = [];
      window.metrics = null;
      fpvCameraRef.current = null;
      fpvRendererRef.current = null;
      controlsRef.current = null;
      controlBarsRef.current = null;
    };
  }, [addLog, simulationRef, isInferenceRunning, selectedModel]);
  // ===================NEW===================


  /**
   * Updates the main camera position relative to the drone.
   * @param {THREE.PerspectiveCamera} camera - The main camera.
   * @param {THREE.Object3D} drone - The drone object.
   */
  const updateCameraPosition = (camera, drone) => {
    if (drone) {
      const cameraOffset = new THREE.Vector3(0, 1, -3);
      const rotatedOffset = cameraOffset.clone().applyQuaternion(drone.quaternion);
      camera.position.set(
        drone.position.x + rotatedOffset.x,
        drone.position.y + rotatedOffset.y,
        drone.position.z + rotatedOffset.z
      );
      camera.lookAt(drone.position);
    }
  };

  /**
   * Updates the FPV camera position and orientation.
   * @param {THREE.WebGLRenderer} fpvRenderer - The FPV renderer.
   * @param {THREE.Object3D} drone - The drone object.
   */
  const updateFPVCameraPosition = (fpvRenderer, drone, fpvCamera) => {
    if (drone && fpvCamera) {
      fpvCamera.position.copy(drone.position);
      const rotationY180 = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, Math.PI, 0));
      const combinedRotation = new THREE.Quaternion().multiplyQuaternions(
        drone.quaternion,
        rotationY180
      );
      fpvCamera.setRotationFromQuaternion(combinedRotation);
      const offset = new THREE.Vector3(-.025, 0.2, -0.4).applyQuaternion(combinedRotation);
      fpvCamera.position.add(offset);
    }
  };

  /**
   * Updates the control bars display with current control inputs.
   * @param {Object} controlBars - The control bar elements.
   * @param {Object} channels - The current control input values.
   */
  const updateControlBarsDisplay = (controlBars, channels) => {
    const { roll, pitch, yaw, throttle } = channels;
    updateControlBar(controlBars.roll, roll * 1500 + 1500);
    updateControlBar(controlBars.pitch, pitch * 1500 + 1500);
    updateControlBar(controlBars.yaw, yaw * 1500 + 1500);
    updateControlBar(controlBars.throttle, Math.round(throttle * 3000));
  };

  // ===================NEW===================


  /**
   * Renders the inference control buttons and the YOLOv5 toggle button.
   */
  const handleStartInference = () => {
    if (!isInferenceRunning) {
      setSelectedModel('MobileNetV2');
      addLog('Inference started with model: MobileNetV2');
    }
  };

  const handleStopInference = () => {
    if (isInferenceRunning) {
      addLog('Inference stopped.');
    }
  };

  // ===================NEW===================
  // Toggle function to switch modes
  const toggleMode = () => {
    setIsHorizonMode(prev => !prev);
  };
  // ===================NEW===================

  // Move state update to useEffect
  useEffect(() => {
    if (isHorizonMode && physicsEngineRef.current) {
      physicsEngineRef.current.setHorizonMode(true);
      addLog('Mode switched to Horizon Mode');
    } else if (!isHorizonMode && physicsEngineRef.current) {
      physicsEngineRef.current.setHorizonMode(false);
      addLog('Mode switched to Acro Mode');
    }
  }, [isHorizonMode, addLog]);

  // Separate useEffect for inference pipeline
  useEffect(() => {
    if (!fpvReady || !isInferenceRunning || !selectedModel) return;

    console.log('Starting inference pipeline with model:', selectedModel);

    const startInferencePipeline = () => {
      const captureAndInfer = () => {
        if (!fpvRendererRef.current || !fpvCameraRef.current) return;

        // Render the scene with FPV camera
        fpvRendererRef.current.render(sceneRef.current, fpvCameraRef.current);

        // Capture the snapshot
        const pixelData = captureFPVSnapshot(fpvRendererRef.current);
        const width = fpvRendererRef.current.domElement.width;
        const height = fpvRendererRef.current.domElement.height;

        if (pixelData) {
          console.log('Captured frame for inference');
          const dims = [1, 3, height, width];
          runInference(pixelData, dims, selectedModel);
        }
      };

      // Initial capture
      captureAndInfer();

      // Set interval for continuous inference
      const intervalId = setInterval(captureAndInfer, 1000);
      return () => clearInterval(intervalId);
    };

    const cleanup = startInferencePipeline();
    return () => {
      if (cleanup) cleanup();
    };
  }, [fpvReady, isInferenceRunning, selectedModel]);

  // Add effect to handle gamepad reconnection after inference stops
  useEffect(() => {
    if (!isInferenceRunning && controlsRef.current?.gamepadHandler) {
      console.log('🎮 Inference stopped, checking gamepad connection...');
      controlsRef.current.gamepadHandler.reconnect();
    }
  }, [isInferenceRunning]);

  // Add keyboard event listener for 'R' key
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key.toLowerCase() === 'r') {
        resetSimulation();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div>
      <div ref={mountRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
        <button
          onClick={resetSimulation}
          style={{
            position: 'absolute',
            bottom: '200px',  // Moved down slightly
            left: '20px',
            padding: '10px 16px',
            backgroundColor: 'rgba(5, 10, 14, 0.95)',
            color: '#00F135',
            border: '1px solid rgba(0, 241, 53, 0.2)',
            borderRadius: '8px',
            cursor: 'pointer',
            fontFamily: 'monospace',
            fontSize: '13px',
            letterSpacing: '1px',
            boxShadow: '0 0 20px rgba(0, 241, 53, 0.1)',
            zIndex: 1000,
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            backdropFilter: 'blur(4px)',
            WebkitBackdropFilter: 'blur(4px)',
            textTransform: 'uppercase'
          }}
          onMouseEnter={(e) => {
            const button = e.currentTarget;
            button.style.backgroundColor = 'rgba(0, 241, 53, 0.15)';
            button.style.transform = 'translateY(-1px)';
            button.style.boxShadow = '0 0 25px rgba(0, 241, 53, 0.2)';
            const icon = button.querySelector('svg');
            if (icon) icon.style.transform = 'rotate(180deg)';
          }}
          onMouseLeave={(e) => {
            const button = e.currentTarget;
            button.style.backgroundColor = 'rgba(5, 10, 14, 0.95)';
            button.style.transform = 'translateY(0)';
            button.style.boxShadow = '0 0 20px rgba(0, 241, 53, 0.1)';
            const icon = button.querySelector('svg');
            if (icon) icon.style.transform = 'rotate(0deg)';
          }}
        >
          <svg
            style={{
              width: '14px',
              height: '14px',
              transition: 'transform 0.3s ease'
            }}
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
          </svg>
          <span>Reset (R)</span>
        </button>
      </div>
    </div>
  );
};

export default Simulation;
