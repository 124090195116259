import React, { useState, useRef } from 'react';
import { FaTimes, FaFile, FaImage, FaCube, FaArrowLeft } from 'react-icons/fa';
import * as styles from './CustomMapModal.module.css';

const CustomMapModal = ({ onClose, onUpload }) => {
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [gltfFile, setGltfFile] = useState(null);
  const [binFile, setBinFile] = useState(null);
  const [textureFiles, setTextureFiles] = useState([]);
  const [glbFile, setGlbFile] = useState(null);
  const [error, setError] = useState('');
  const [plyFile, setPlyFile] = useState(null);
  
  const gltfInputRef = useRef(null);
  const binInputRef = useRef(null);
  const textureInputRef = useRef(null);
  const glbInputRef = useRef(null);
  const plyInputRef = useRef(null);

  const handleGltfSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.gltf')) {
      setGltfFile(file);
      setError('');
    } else {
      setError('Please select a valid .gltf file');
    }
  };

  const handleBinSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.bin')) {
      setBinFile(file);
      setError('');
    } else {
      setError('Please select a valid .bin file');
    }
  };

  const handleTextureSelect = (event) => {
    const files = Array.from(event.target.files);
    const validTextureTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const validFiles = files.filter(file => validTextureTypes.includes(file.type));
    
    if (validFiles.length !== files.length) {
      setError('Some texture files are not in a valid format (JPG, PNG, or WEBP)');
    } else {
      setTextureFiles(prevFiles => [...prevFiles, ...validFiles]);
      setError('');
    }
  };

  const removeTexture = (index) => {
    setTextureFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleGlbSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.glb')) {
      setGlbFile(file);
      setError('');
    } else {
      setError('Please select a valid .glb file');
    }
  };

  const handlePlySelect = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.ply')) {
      setPlyFile(file);
      setError('');
    } else {
      setError('Please select a valid .ply file');
    }
  };

  const handleUpload = async () => {
    if (selectedFormat === 'ply') {
      if (!plyFile) {
        setError('Please provide a PLY file');
        return;
      }
      try {
        await onUpload({ ply: plyFile });
        onClose();
      } catch (error) {
        setError(error.message);
      }
    } else if (selectedFormat === 'glb') {
      if (!glbFile) {
        setError('Please provide a GLB file');
        return;
      }
      try {
        await onUpload({ glb: glbFile });
        onClose();
      } catch (error) {
        setError(error.message);
      }
    } else {
      if (!gltfFile || !binFile) {
        setError('Please provide both GLTF and BIN files');
        return;
      }
      try {
        const mapData = {
          gltf: gltfFile,
          bin: binFile,
          textures: textureFiles
        };
        await onUpload(mapData);
        onClose();
      } catch (error) {
        setError(error.message);
      }
    }
  };

  if (!selectedFormat) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>

          <h2>Choose Format</h2>
          
          <div className={styles.formatSelection}>
            <button 
              className={styles.formatButton}
              onClick={() => setSelectedFormat('glb')}
            >
              <FaCube />
              <span>GLB File</span>
              <small>Single file format</small>
            </button>

            <button 
              className={styles.formatButton}
              onClick={() => setSelectedFormat('gltf')}
            >
              <FaFile />
              <span>GLTF + Bin</span>
              <small>Multi-file format</small>
            </button>

            <button 
              className={styles.formatButton}
              onClick={() => setSelectedFormat('ply')}
            >
              <FaCube />
              <span>PLY File</span>
              <small>Point cloud / Mesh format</small>
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (selectedFormat === 'ply') {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>

          <button 
            className={styles.backButton}
            onClick={() => setSelectedFormat(null)}
          >
            <FaArrowLeft /> Back to Format Selection
          </button>

          <h2>Upload PLY File</h2>
          
          <div className={styles.uploadSection}>
            <div className={styles.fileInput}>
              <input
                ref={plyInputRef}
                type="file"
                accept=".ply"
                onChange={handlePlySelect}
                style={{ display: 'none' }}
              />
              <button 
                onClick={() => plyInputRef.current?.click()}
                className={styles.uploadButton}
              >
                <FaCube /> Select PLY File
              </button>
              {plyFile && <span className={styles.fileName}>{plyFile.name}</span>}
            </div>
          </div>

          {error && <div className={styles.error}>{error}</div>}

          <div className={styles.actions}>
            <button 
              onClick={handleUpload}
              className={styles.submitButton}
              disabled={!plyFile}
            >
              Upload Map
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (selectedFormat === 'glb') {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>

          <button 
            className={styles.backButton}
            onClick={() => setSelectedFormat(null)}
          >
            <FaArrowLeft /> Back to Format Selection
          </button>

          <h2>Upload GLB File</h2>
          
          <div className={styles.uploadSection}>
            <div className={styles.fileInput}>
              <input
                ref={glbInputRef}
                type="file"
                accept=".glb"
                onChange={handleGlbSelect}
                style={{ display: 'none' }}
              />
              <button 
                onClick={() => glbInputRef.current?.click()}
                className={styles.uploadButton}
              >
                <FaCube /> Select GLB File
              </button>
              {glbFile && <span className={styles.fileName}>{glbFile.name}</span>}
            </div>
          </div>

          {error && <div className={styles.error}>{error}</div>}

          <div className={styles.actions}>
            <button 
              onClick={handleUpload}
              className={styles.submitButton}
              disabled={!glbFile}
            >
              Upload Map
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          <FaTimes />
        </button>

        <button 
          className={styles.backButton}
          onClick={() => setSelectedFormat(null)}
        >
          <FaArrowLeft /> Back to Format Selection
        </button>

        <h2>Upload Custom Map Files</h2>
        
        <div className={styles.uploadSection}>
          <h3>GLTF File</h3>
          <div className={styles.fileInput}>
            <input
              ref={gltfInputRef}
              type="file"
              accept=".gltf"
              onChange={handleGltfSelect}
              style={{ display: 'none' }}
            />
            <button 
              onClick={() => gltfInputRef.current?.click()}
              className={styles.uploadButton}
            >
              <FaFile /> Select GLTF
            </button>
            {gltfFile && <span className={styles.fileName}>{gltfFile.name}</span>}
          </div>
        </div>

        <div className={styles.uploadSection}>
          <h3>BIN File</h3>
          <div className={styles.fileInput}>
            <input
              ref={binInputRef}
              type="file"
              accept=".bin"
              onChange={handleBinSelect}
              style={{ display: 'none' }}
            />
            <button 
              onClick={() => binInputRef.current?.click()}
              className={styles.uploadButton}
            >
              <FaFile /> Select BIN
            </button>
            {binFile && <span className={styles.fileName}>{binFile.name}</span>}
          </div>
        </div>

        <div className={styles.uploadSection}>
          <h3>Texture Files</h3>
          <div className={styles.fileInput}>
            <input
              ref={textureInputRef}
              type="file"
              accept=".jpg,.jpeg,.png,.webp"
              multiple
              onChange={handleTextureSelect}
              style={{ display: 'none' }}
            />
            <button 
              onClick={() => textureInputRef.current?.click()}
              className={styles.uploadButton}
            >
              <FaImage /> Add Textures
            </button>
          </div>
          
          <div className={styles.textureList}>
            {textureFiles.map((file, index) => (
              <div key={index} className={styles.textureItem}>
                <span>{file.name}</span>
                <button 
                  onClick={() => removeTexture(index)}
                  className={styles.removeButton}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.actions}>
          <button 
            onClick={handleUpload}
            className={styles.submitButton}
            disabled={!gltfFile || !binFile}
          >
            Upload Map
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomMapModal; 