import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaArrowLeft, FaMemory, FaClock, FaMicrochip, FaChartLine } from 'react-icons/fa';
import * as styles from './PerformanceHub.module.css';

const PerformanceHub = ({ onClose }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [metrics, setMetrics] = useState({
    fps: 0,
    memory: 0,
    physicsTime: 0,
    renderTime: 0
  });

  // Update metrics every frame
  useEffect(() => {
    let frameId;
    const updateMetrics = () => {
      if (window.metrics) {
        setMetrics({
          fps: window.metrics.fps || 0,
          memory: Math.round((window.metrics.memory || 0) / (1024 * 1024)), // Convert to MB
          physicsTime: window.metrics.physicsTime?.toFixed(2) || 0,
          renderTime: window.metrics.renderTime?.toFixed(2) || 0
        });
      }
      frameId = requestAnimationFrame(updateMetrics);
    };

    frameId = requestAnimationFrame(updateMetrics);
    return () => cancelAnimationFrame(frameId);
  }, []);

  const handleClose = useCallback(() => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  return (
    <div className={`${styles.performanceHub} ${isExiting ? styles.exiting : ''}`}>
      <button className={styles.backButton} onClick={handleClose}>
        <FaArrowLeft /> Back
      </button>
      
      <div className={styles.metricsSection}>
        <h3>Performance Metrics</h3>
        <div className={styles.metricsList}>
          <div className={styles.metricCard}>
            <div className={styles.metricHeader}>
              <FaChartLine />
              <h3>FPS</h3>
            </div>
            <div className={styles.metricValue}>{metrics.fps}</div>
          </div>

          <div className={styles.metricCard}>
            <div className={styles.metricHeader}>
              <FaMemory />
              <h3>Memory Usage</h3>
            </div>
            <div className={styles.metricValue}>{metrics.memory} MB</div>
          </div>

          <div className={styles.metricCard}>
            <div className={styles.metricHeader}>
              <FaMicrochip />
              <h3>Physics Time</h3>
            </div>
            <div className={styles.metricValue}>{metrics.physicsTime} ms</div>
          </div>

          <div className={styles.metricCard}>
            <div className={styles.metricHeader}>
              <FaClock />
              <h3>Render Time</h3>
            </div>
            <div className={styles.metricValue}>{metrics.renderTime} ms</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceHub;