import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import * as Ammo from 'ammo.js';

/**
 * @class DroneScene
 * @extends THREE.Scene
 * @description Manages the 3D scene for the drone simulation, including the drone model and environment.
 */
class DroneScene extends THREE.Scene {
  /**
   * @constructor
   * @param {Function} addLog - Function to log messages.
   */
  constructor(addLog) {
    super();
    this.drone = null;
    this.droneAnimations = [];
    this.animationMixer = null;
    this.environment = null;
    this.addLog = addLog;
    this.addLog('DroneScene constructor: Scene initialized');
    // Define initial state
    this.initialDronePosition = new THREE.Vector3(0, 5, 0);
    this.initialDroneRotation = new THREE.Euler(0, 0, 0);
    this.initialDroneQuaternion = new THREE.Quaternion().setFromEuler(this.initialDroneRotation);
    this.physicsWorld = null;
  }

  /**
   * @method init
   * @async
   * @description Initializes the scene, setting up lights, creating the drone, and loading the environment.
   */
  async init() {
    this.addLog('DroneScene init: Initializing scene');
    console.log('DroneScene init started');

    try {
      // Add ambient light
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.add(ambientLight);
      this.addLog('DroneScene init: Ambient light added');

      // Add directional light with shadows
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(10, 10, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 2048;
      directionalLight.shadow.mapSize.height = 2048;
      directionalLight.shadow.camera.near = 0.5;
      directionalLight.shadow.camera.far = 500;
      this.add(directionalLight);
      this.addLog('DroneScene init: Directional light added');

      // Load drone model
      await this.loadDroneModel();
      console.log('Drone model loaded successfully');

      // Load environment
      await this.loadEnvironment();
      console.log('Environment loaded successfully');

      // Set sky color
      const skyColor = new THREE.Color(0x87CEEB);
      this.background = skyColor;
      
      console.log('DroneScene initialization complete', {
        hasDrone: !!this.drone,
        dronePosition: this.drone?.position?.toArray(),
        initialPosition: this.initialDronePosition.toArray(),
        initialRotation: this.initialDroneQuaternion.toArray()
      });
      
      this.addLog('DroneScene init: Scene initialized successfully');
    } catch (error) {
      console.error('DroneScene initialization error:', error);
      this.addLog(`DroneScene init: Initialization failed - ${error.message}`);
      throw error;
    }
  }

  /**
   * @method loadDroneModel
   * @private
   * @returns {Promise<void>} A promise that resolves when the drone model is loaded
   * @description Loads the drone 3D model with animations, configures materials and shadows,
   * and sets up the initial position and rotation. Uses DRACO compression for optimal performance.
   */
  async loadDroneModel() {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        '/assets/models/drone/droneforge.glb',
        (gltf) => {
          this.drone = gltf.scene;
          this.drone.scale.set(.0045, .0045, .0045);
          this.drone.position.copy(this.initialDronePosition);
          this.drone.quaternion.copy(this.initialDroneQuaternion);

          // Traverse the drone model to ensure materials are properly applied
          this.drone.traverse((child) => {
            if (child.isMesh) {
              // Ensure the material is using its map (texture)
              if (child.material.map) {
                child.material.map.colorSpace = THREE.SRGBColorSpace;
                child.material.map.flipY = false; // GLB files typically don't need texture flipping
                child.material.needsUpdate = true;
              }
              
              // Apply these settings to other texture types if present
              ['normalMap', 'roughnessMap', 'metalnessMap'].forEach(mapType => {
                if (child.material[mapType]) {
                  child.material[mapType].colorSpace = THREE.LinearSRGBColorSpace;
                  child.material[mapType].flipY = false;
                }
              });

              // Enable shadows for each mesh
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });

          this.add(this.drone);

          // Set up animations
          this.animationMixer = new THREE.AnimationMixer(this.drone);
          this.droneAnimations = gltf.animations;
          
          // Play all animations
          this.droneAnimations.forEach((clip) => {
            const action = this.animationMixer.clipAction(clip);
            action.play();
          });

          this.addLog('DroneScene loadDroneModel: Drone model and animations loaded successfully');
          resolve();
        },
        (xhr) => {
          this.addLog(`DroneScene loadDroneModel: ${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          this.addLog(`DroneScene loadDroneModel: An error occurred - ${error.message}`);
          reject(error);
        }
      );
    });
  }

  /**
   * @method loadEnvironment
   * @private
   * @returns {Promise} A promise that resolves when the environment is loaded.
   * @description Loads the 3D environment model and adds it to the scene.
   */
  loadEnvironment() {
    return new Promise((resolve, reject) => {
      console.log('Starting environment load...');
      const loader = new GLTFLoader();

      // Use DRACOLoader for compressed meshes
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);

      // Load the default environment model
      loader.load(
        '/assets/models/gltf_enviorment3/Map_v1.gltf',
        (gltf) => {
          console.log('Environment model loaded successfully');
          if (this.environment) {
            this.remove(this.environment);
          }
          this.environment = gltf.scene;
          this.add(this.environment);
          this.addLog('DroneScene loadEnvironment: Environment loaded successfully');
          resolve();
        },
        (xhr) => {
          console.log(`Loading progress: ${(xhr.loaded / xhr.total) * 100}% loaded`);
          this.addLog(`DroneScene loadEnvironment: ${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          console.error('Error loading environment:', error);
          this.addLog(`DroneScene loadEnvironment: An error occurred - ${error.message}`);
          reject(error);
        }
      );
    });
  }

  /**
   * @method update
   * @param {number} deltaTime - Time elapsed since the last frame in seconds
   * @description Updates the animation state of the drone model.
   * This method should be called in the animation loop.
   */
  update(deltaTime) {
    // Update animations
    if (this.animationMixer) {
      this.animationMixer.update(deltaTime);
    }
  }

  /**
   * @method resetDroneToSpawn
   * @description Resets the drone to its initial spawn position and rotation.
   * Also resets the physics body state if it exists, including position, rotation,
   * and velocities. This is used when restarting the simulation or respawning the drone.
   */
  resetDroneToSpawn() {
    if (this.drone) {
      this.drone.position.copy(this.initialDronePosition);
      this.drone.quaternion.copy(this.initialDroneQuaternion);
      
      const physicsBody = this.drone.userData.physicsBody;
      if (physicsBody) {
        const transform = new Ammo.btTransform();
        transform.setIdentity();
        transform.setOrigin(new Ammo.btVector3(
          this.initialDronePosition.x,
          this.initialDronePosition.y,
          this.initialDronePosition.z
        ));
        transform.setRotation(new Ammo.btQuaternion(
          this.initialDroneQuaternion.x,
          this.initialDroneQuaternion.y,
          this.initialDroneQuaternion.z,
          this.initialDroneQuaternion.w
        ));
        
        const motionState = physicsBody.getMotionState();
        if (motionState) {
          motionState.setWorldTransform(transform);
        }
        physicsBody.setWorldTransform(transform);
        
        // Reset velocities
        const zero = new Ammo.btVector3(0, 0, 0);
        physicsBody.setLinearVelocity(zero);
        physicsBody.setAngularVelocity(zero);
        
        // Cleanup
        Ammo.destroy(zero);
        Ammo.destroy(transform);
      }
    }
  }

  /**
   * @method setDronePosition
   * @param {THREE.Vector3} newPosition - The new position to set for the drone
   * @returns {boolean} Returns true if position was set successfully, false if drone doesn't exist
   * @description Sets the drone's position in both the 3D scene and physics world.
   * Updates the visual model and synchronizes the physics body if it exists.
   * Also resets velocities and ensures the physics body is active.
   * 
   * @example
   * const newPos = new THREE.Vector3(0, 10, 0);
   * droneScene.setDronePosition(newPos);
   */
  setDronePosition(newPosition) {
    if (this.drone) {
      console.log('Setting drone position:', {
        newPosition,
        currentPosition: this.drone.position.clone(),
        physicsBody: !!this.drone.userData.physicsBody
      });

      // Update Three.js model position first
      this.initialDronePosition.copy(newPosition);
      this.drone.position.copy(newPosition);
      
      // Update physics body if it exists
      const physicsBody = this.drone.userData.physicsBody;
      if (physicsBody) {
        // Create and configure transform
        const transform = new Ammo.btTransform();
        transform.setIdentity();
        
        // Create a new btVector3 for position
        const pos = new Ammo.btVector3(newPosition.x, newPosition.y, newPosition.z);
        transform.setOrigin(pos);
        
        // Set rotation
        const quat = new Ammo.btQuaternion(
          this.initialDroneQuaternion.x,
          this.initialDroneQuaternion.y,
          this.initialDroneQuaternion.z,
          this.initialDroneQuaternion.w
        );
        transform.setRotation(quat);
        
        // Update motion state and transform
        const motionState = physicsBody.getMotionState();
        if (motionState) {
          motionState.setWorldTransform(transform);
        }
        physicsBody.setWorldTransform(transform);
        
        // Reset velocities and activate
        const zero = new Ammo.btVector3(0, 0, 0);
        physicsBody.setLinearVelocity(zero);
        physicsBody.setAngularVelocity(zero);
        physicsBody.activate(true);
        
        // Force update the physics world
        physicsBody.setCenterOfMassTransform(transform);
        
        // Verify the update
        const newTransform = physicsBody.getWorldTransform();
        const newPos = newTransform.getOrigin();
        console.log('Physics body after update:', {
          x: newPos.x(),
          y: newPos.y(),
          z: newPos.z()
        });
        
        // Cleanup Ammo.js objects
        Ammo.destroy(pos);
        Ammo.destroy(quat);
        Ammo.destroy(zero);
        Ammo.destroy(transform);
      }

      // Force one final visual update
      this.drone.position.copy(newPosition);
      
      console.log('Final positions:', {
        visual: this.drone.position.clone(),
        physics: physicsBody ? {
          x: physicsBody.getWorldTransform().getOrigin().x(),
          y: physicsBody.getWorldTransform().getOrigin().y(),
          z: physicsBody.getWorldTransform().getOrigin().z()
        } : null
      });
      
      return true;
    }
    return false;
  }
}

export default DroneScene;
